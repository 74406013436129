import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';

import { AppContext } from '../../Context/AppContext';

function About() {
  const { echoTrack } = useContext(AppContext);

  useEffect(() => {
    echoTrack('about');

    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet title="About | BBC Sound Effects" />
      <div className="max-w-screen-xl min-h-screen px-3 mx-auto">
        <h2 className="py-5 text-3xl font-bold md:py-10">About</h2>
        <div className="pb-10 space-y-5">
          <p>
            The BBC Sound Effects Archive is available for personal, educational or research purposes.
            There are over 33,000 clips from across the world from the past 100 years.
            These include clips made by the BBC Radiophonic workshop, recordings from the Blitz in London,
            special effects made for BBC TV and Radio productions,
            as well as 15,000 recordings from the Natural History Unit archive.
            You can explore sounds from every continent -
            from the college bells ringing in Oxford to a Patagonian waterfall -
            or listen to a submarine klaxon or the sound of a 1969 Ford Cortina door slamming shut.
          </p>
          <p>As well as browsing and searching through this treasure trove of sounds,
          you can also make and share your own mixes and soundscapes using the ‘mixer mode’ function.
          Use the mixer to layer, edit and re-order clips from the archive to create your own sounds.
          </p>
          <h2 className="text-xl font-bold">The Natural History Unit Collection</h2>
          <p>
            The Natural History sound collection originated from the need to provide authentic
            natural sound for the Natural History Unit’s television and radio productions, and
            its burgeoning output during 1960s. Most people probably don’t realise that the majority
            of natural history television is recorded mute; the recording demands of cameras and
            microphones being very different; and generally you need to get a lot closer to your subject
            to record the sound of an animal than you do to film it; so for the majority of the time the
            sound is added during a production’s edit. It meant that the Unit needed to build up a library
            of good quality natural sounds that would become an ongoing resource and fill the gaps for productions
            when they were unable to record them convincingly at the time of a shoot.
          </p>
          <p>
            The Unit began to assimilate and incorporate the sounds collected by production recordists as they returned
            from the field, whilst also employing its own dedicated sound recordist to collect natural sounds and
            commissioning recordings from others. Gradually the sound collection was built and has since become one of
            the country’s largest collection of natural sounds.
          </p>
          <p>
            The original collection was housed on vinyl, before being replaced by quarter-inch tape, and then CD,
            eventually moving onto a digital base. Sound doesn’t date in the same way that film does, so recordings
            made on tape in the 1970s can still be used in high quality productions today.
          </p>
          <p>
            The collection was largely established between the 1960s and the early 2000s, at which point the demands of
            productions and the benefits of digital recording outweighed the necessity of developing the collection
            further.
          </p>
          <p>
            The earliest recording in the collection is probably the world’s earliest natural history sound
            recording – made onto wax cylinder in 1889 by Ludwig Koch, and later bequeathed to the BBC. Some of the
            early BBC field recordings were made by David Attenborough, and others by recordist’s such as David
            Toombs and Nigel Tucker in the 1980s, and Chris Watson in more recent years.
          </p>
          <p>
            The collection truly has a worldwide range of subjects, most of which can be divided broadly into specific
            species recordings and those of natural atmospheres, though there are also various recordings of natural
            phenomena, as well as some tribal chants and music.
          </p>
        </div>
      </div>
    </>
  );
}

export default About;
