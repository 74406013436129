import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

import { AppContext } from '../../Context/AppContext';
import { MixerContext } from '../../Context/MixerContext';
import { FavouritesContext } from '../../Context/FavouritesContext';

import GelIcon from '../GelIcon';
import AudioPlayer from './AudioPlayer';
import UserButtons from './UserButtons';
import MoreDetails from './MoreDetails';
import { THEME_COLOURS } from '../../constants';

const LoadingPlaceholder = () => (
  <div className="absolute w-full ml-4 lg:-mx-6 lg:mr-0">
    <GelIcon name="loading" fill={THEME_COLOURS.DARK_GREY} className="w-8 h-8 mx-auto my-3 opacity-25 animate-spin" />
  </div>
);

function SearchResultItem({ asset }) {
  const [showDetails, setShowDetails] = useState(false);
  const [isLooping, setIsLooping] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [showDownloadPopover, setShowDownloadPopover] = useState(false);

  const { echoTrack } = useContext(AppContext);
  const {
    state: { mixerModeOn, mixerAssets },
    addMixerAsset,
  } = useContext(MixerContext);
  const { state: { favouriteIds }, updateFavourites } = useContext(FavouritesContext);
  const [favourited, setFavourited] = useState(favouriteIds.includes(asset.id));

  const handleFavouriteClick = () => {
    const newFavouriteIds = [...favouriteIds];
    if (favourited) {
      echoTrack('audioplayer', { action: 'asset_favourited', data: { assetId: asset.id } }, 'click');
      newFavouriteIds.splice(newFavouriteIds.indexOf(asset.id), 1);
    } else {
      newFavouriteIds.push(asset.id);
    }

    updateFavourites(newFavouriteIds);
  };

  useEffect(() => {
    if (favouriteIds.includes(asset.id)) {
      setFavourited(true);
    } else {
      setFavourited(false);
    }
  }, [favouriteIds, asset.id]);

  const handleMixerClick = () => {
    echoTrack('audioplayer', { action: 'asset_add_to_mixer', data: { assetId: asset.id } }, 'click');

    const success = addMixerAsset(asset);
    if (success) {
      setIsAdded(true);
    }
  };

  useEffect(() => {
    if (!isAdded) return;

    const timeout = setTimeout(() => { setIsAdded(false); }, 2000);

    return () => clearTimeout(timeout);
  }, [isAdded]);

  // Store count of number of times asset is in mix
  const assetsInMix = Object.keys(mixerAssets).map(index => mixerAssets[index].id === asset.id).filter(Boolean).length;

  return (
    <div className="flex flex-row items-stretch mb-3 bg-white shadow-md disable-dbl-tap-zoom" id={asset.id}>
      <button
        type="button"
        className={`focus:outline-none ${mixerModeOn ? 'lg:block border-r border-gray-400 hover:bg-gray-200 focus:bg-gray-200' : 'lg:hidden'} hidden`}
        onClick={handleMixerClick}
      >
        <div className="px-8 pt-4">
          <GelIcon
            name={!isAdded ? 'add' : 'check'}
            fill={!isAdded ? THEME_COLOURS.PRIMARY : THEME_COLOURS.SECONDARY}
            className={`w-6 h-6 transform duration-500 ease-out ${!isAdded ? 'scale-100' : 'scale-125'}`}
          />
        </div>
        <div className="pt-2 text-xs text-center">{!isAdded ? 'Add to mixer' : 'Added'}</div>
        {
          assetsInMix !== 0 && (
            <div className="inline-flex px-2 text-xs font-bold text-white truncate bg-blue-800 rounded-md">{`${assetsInMix} in mix`}</div>
          )
        }
      </button>
      <div className="w-full lg:px-6">
        <button
          type="button"
          className={`focus:outline-none lg:hidden ${mixerModeOn ? 'block border-b border-gray-400 hover:bg-gray-200 focus:bg-gray-200' : 'hidden'} w-full`}
          onClick={handleMixerClick}
        >
          <div className='flex flex-row items-center justify-between px-4 py-5'>
            <div className="flex flex-row items-center">
              <GelIcon
                name={!isAdded ? 'add' : 'check'}
                fill={!isAdded ? THEME_COLOURS.PRIMARY : THEME_COLOURS.SECONDARY}
                className="w-5 h-5"
              />
              <span className="ml-2 text-sm">{!isAdded ? 'Add to mixer' : 'Added'}</span>
            </div>
            {
              assetsInMix !== 0 && (
                <div className="inline-flex px-2 text-sm font-bold text-white truncate bg-blue-800 rounded-md">{`${assetsInMix} in mix`}</div>
              )
            }
          </div>
        </button>
        <div className="px-6 py-6 lg:px-0 lg:mx-0 lg:flex lg:flex-row">
          <div className="w-full lg:flex lg:flex-row">
            <LazyLoad once placeholder={<LoadingPlaceholder />} offset={250} classNamePrefix="relative w-full h-10 lg:h-16 lg:w-1/2 lazyload">
              <div className="flex flex-row-reverse items-center justify-between lg:flex-row">
                <AudioPlayer
                  asset={asset}
                  isLooping={isLooping}
                  setIsLooping={setIsLooping}
                  favourited={favourited}
                  showDownloadPopover={showDownloadPopover}
                  setShowDownloadPopover={setShowDownloadPopover}
                  handleFavouriteClick={handleFavouriteClick}
                />
                <div className="flex flex-row items-center lg:space-x-4 lg:hidden">
                  <div className="relative">
                    <button type="button" className="p-2 mr-2 -ml-2 rounded-md focus:outline-none focus:bg-gray-300 hover:bg-gray-300" onClick={() => setIsLooping(!isLooping)} aria-label={isLooping ? 'Disable looping' : 'Enable looping'}>
                      <GelIcon name={isLooping ? 'movement-on' : 'movement-off'} fill={isLooping ? THEME_COLOURS.TERTIARY : THEME_COLOURS.DARK_GREY} className="block w-6 h-6 lg:hidden" />
                    </button>
                  </div>
                </div>
              </div>
            </LazyLoad>
            <div className="flex-row items-center justify-between mt-8 lg:flex lg:w-1/2 lg:mt-0">
              <p className="text-sm leading-snug text-gray-900 lg:px-2">{asset.description}</p>
              <div className="flex flex-row items-center mt-4 space-x-4 lg:mt-0">
                {/* Will show on medium screens upwards */}
                <UserButtons
                  asset={asset}
                  isLooping={isLooping}
                  setIsLooping={setIsLooping}
                  favourited={favourited}
                  handleFavouriteClick={handleFavouriteClick}
                  showDownloadPopover={showDownloadPopover}
                  setShowDownloadPopover={setShowDownloadPopover}
                  mixerVersion={false}
                />
                <button
                  type="button"
                  className="flex flex-row items-center px-1 py-2 rounded-md lg:p-0 hover:bg-gray-300 lg:hover:bg-transparent focus:outline-none hover:underline focus:underline"
                  onClick={() => setShowDetails(!showDetails)}
                  aria-label={showDetails ? 'Hide details' : 'Show details'}
                >
                  <div className="hidden font-bold lg:block lg:mr-3 lg:w-28">{`${showDetails ? 'Hide' : 'Show'} details`}</div>
                  <GelIcon name={showDetails ? 'chevron-up' : 'chevron-down'} className="w-6 h-6 mx-1 lg:w-4 lg:mx-0" fill={THEME_COLOURS.PRIMARY} />
                </button>
              </div>
            </div>
          </div>
        </div>
        {
          showDetails && <MoreDetails asset={asset} mixerVersion={false} />
        }
      </div>
    </div>
  );
}

SearchResultItem.propTypes = {
  asset: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
};

export default SearchResultItem;
