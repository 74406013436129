import React, { useState, useEffect, useRef, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { AppContext } from '../../Context/AppContext';

import SEARCH_SUGGESTIONS from './searchSuggestions';
import GelIcon from '../GelIcon';
import { THEME_COLOURS } from '../../constants';
import getQuerystring from '../../utilities/getQuerystring';

const Highlighted = ({ text = '', highlight = '' }) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi');
  const parts = text.split(regex);
  return (
    <span>
      {parts.filter(part => part).map((part, i) => (
        regex.test(part) ? <span key={i} className="font-bold text-gray-500 md:text-gray-900">{part}</span> : <span key={i} className="text-gray-600">{part}</span>
      ))}
    </span>
  );
};

Highlighted.propTypes = {
  text: PropTypes.string,
  highlight: PropTypes.string
};

function SearchInput({ history, hidePlaceholder, searchIsFocused, setSearchIsFocused }) {
  const { echoTrack } = useContext(AppContext);
  const inputRef = useRef(null);

  // Get search term from query string
  const parsedQuerystring = getQuerystring();

  // Use search term or default to empty
  const [searchText, setSearchText] = useState(parsedQuerystring.q);

  const performSearch = () => {
    // This will reset other search filters
    if (searchText !== parsedQuerystring.q) {
      echoTrack('search_input', { action: 'search_performed', data: { searchText } }, 'click');
      history.push(searchText.length > 0 ? `/search?q=${searchText}` : '/search');
    }

    // Force blur the input
    inputRef.current.blur();
    setSearchIsFocused(false);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchIsFocused(false);

    echoTrack('search_input', { action: 'search_suggsetion', data: { suggestion } }, 'click');

    history.push(`/search?q=${suggestion}`);
  };

  useEffect(() => {
    // Set the search bar text to empty if user navigates away from search page
    if (history.location.pathname !== '/search') {
      setSearchText('');
    } else {
      // Else set it to the current 'q' param
      setSearchText(parsedQuerystring.q);
    }
  }, [history.location, parsedQuerystring.q]);

  useEffect(() => {
    if (searchIsFocused) {
      document.documentElement.style.position = 'fixed';
      document.documentElement.style.width = '100%';
    } else {
      document.documentElement.style.position = '';
      document.documentElement.style.width = '';
    }
  }, [searchIsFocused]);

  // Filter the search suggestions down based on inputted text
  // Only show suggestions when search query is 2 characters or more
  const filteredSuggestions = (searchText && searchText.length > 1)
    ? SEARCH_SUGGESTIONS.filter(word => word.includes(searchText.toLowerCase()))
    : [];

  return (
    <div className={`${!searchIsFocused ? 'relative' : 'md:absolute'} flex flex-col w-full md:flex-row md:items-center`} style={{ ...(searchIsFocused && { top: -26 }) }}>
      <div className={`relative z-30 flex flex-col items-center w-full bg-white rounded-3xl md:border-2 ${searchIsFocused ? 'border-teal-700' : 'border-gray-400'} ${filteredSuggestions.length > 0 && searchIsFocused ? 'rounded-br-none rounded-bl-none md:rounded-br-2xl md:rounded-bl-3xl' : ''}`}>
        <label className="sr-only" htmlFor={`search-input-${!hidePlaceholder ? 'desktop' : 'mobile'}`}>Search</label>
        <input
          id={`search-input-${!hidePlaceholder ? 'desktop' : 'mobile'}`}
          ref={inputRef}
          className={`w-full p-3 text-gray-900 bg-transparent focus:outline-none`}
          placeholder={!hidePlaceholder ? 'Search BBC Sound Effects' : ''}
          type="search"
          onChange={(e) => setSearchText(e.currentTarget.value)}
          value={searchText || ''}
          onKeyPress={(e) => e.key === 'Enter' && performSearch()}
          onFocus={() => setSearchIsFocused(true)}
          maxLength="500"
        />
        {
          (searchText && searchIsFocused && filteredSuggestions.length > 0) && (
            <div className="absolute top-0 w-full mt-12 bg-gray-900 md:mt-0 md:relative md:top-auto md:bg-white rounded-br-3xl rounded-bl-3xl md:border-t-2 md:border-gray-700">
              <ul className="px-5 py-3 space-y-3 overflow-auto" style={{ maxHeight: 200 }}>
                {
                  filteredSuggestions.map(suggestion => (
                    <li key={suggestion}>
                      <button type="button" className="w-full text-xl text-left truncate focus:outline-none md:text-base md:text-gray-900 hover:underline focus:underline" onClick={() => handleSuggestionClick(suggestion)} >
                        <Highlighted text={suggestion} highlight={searchText} />
                      </button>
                    </li>
                  ))
                }
              </ul>
            </div>
          )
        }
        <div className="absolute right-0" style={{ top: 8 }}>
          <button
            type="button"
            title="Search"
            className="h-full p-1 mr-2 rounded-lg focus:outline-none hover:bg-gray-300 focus:bg-gray-300"
            onClick={performSearch}
            onFocus={() => setSearchIsFocused(false)}
            aria-label="Perform search"
          >
            <GelIcon name="search" fill={THEME_COLOURS.SECONDARY} />
          </button>
        </div>
      </div>
    </div>
  );
}

SearchInput.defaultProps = {
  hidePlaceholder: false,
};

SearchInput.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  }),
  hidePlaceholder: PropTypes.bool,
  searchIsFocused: PropTypes.bool,
  setSearchIsFocused: PropTypes.func,
};

export default withRouter(SearchInput);
