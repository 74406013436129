import React from 'react';
import PropTypes from 'prop-types';

import GelIcon from '../GelIcon';
import { THEME_COLOURS } from '../../constants';

const FilterPill = ({ filterValue, iconName, viewBox, closeAction }) => {
  return (
    <button
      type="button"
      key={filterValue}
      onClick={closeAction}
      className="flex flex-row items-center justify-between px-2 py-1 mb-1 mr-2 text-xs font-bold text-teal-700 bg-white border border-teal-700 rounded-full focus:outline-none hover:bg-gray-400 focus:bg-gray-400"
      title={`Remove '${filterValue}' filter`}
    >
      <div className="flex flex-row items-center justify-between">
        <GelIcon name={iconName} fill={THEME_COLOURS.SECONDARY} className="w-4 h-4 mr-1" viewBox={viewBox} />
        {filterValue}
        &nbsp;&nbsp;
      </div>
      <GelIcon name="close" fill={THEME_COLOURS.SECONDARY} className="w-3 h-3" />
    </button>
  );
};

FilterPill.propTypes = {
  filterValue: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  viewBox: PropTypes.string,
  closeAction: PropTypes.func.isRequired,
};

export default FilterPill;
