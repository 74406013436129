import React, { createContext, useReducer, useMemo } from "react";
import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import _ from 'lodash';

import {
  API_URL,
  MIXER_CONFIG,
} from "../../constants";
import {
  IMPORT_MIX_ASYNC,
  IMPORT_MIX_ERROR,
  IMPORT_MIX_SUCCESS,
  SET_MIXER_ASSET_SETTINGS,
  SET_MIXER_EDIT_ASSET,
  SET_MIXER_OPEN,
  SET_MIXER_PLAYBACK_RESET,
  SET_MIXER_PLAYING,
  UPDATE_MIXER_ASSETS,
  SET_MIXER_MODE,
  ADD_PLAYING_MIXER_ASSET,
  REMOVE_PLAYING_MIXER_ASSET,
  ADD_READY_MIXER_ASSET,
  REMOVE_READY_MIXER_ASSET,
} from "../actionTypes";

import initialState from "./initialState";
import MixerReducer from "./MixerReducer";
import queryBuilder from "../../utilities/queryBuilder";
import useCustomSnackbars from "../../hooks/useCustomSnackbars";
import processDescriptions from "../../utilities/processDescriptions";

export const MixerContext = createContext(initialState);

export const MixerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MixerReducer, initialState);
  const { openDefaultSnackbar, openErrorSnackbar } = useCustomSnackbars();

  function importMixerAssets(mixerAssetsData) {
    // Pull out unique asset ids from simplified data
    const mixerAssetIds = _.uniq(Object.keys(mixerAssetsData).map(key => mixerAssetsData[key].id));

    // Pass mixer asset ids as a concatenated string
    const mixerAssetIdsString = mixerAssetIds ? `"${mixerAssetIds.join(`","`)}"` : '';

    const criteria = {
      ...queryBuilder(),
      size: MIXER_CONFIG.sizeLimit,
      query: mixerAssetIdsString
    };

    dispatch({
      type: IMPORT_MIX_ASYNC
    });

    // Fetch data
    fetch(`${API_URL}/api/sfx/search`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ criteria }),
    })
      .then(res => res.json())
      .then(data => {
        // Process data
        const results = data.results.map(result => processDescriptions(result));

        // Add data to simplified mixer asset info
        Object.keys(mixerAssetsData).forEach(key => {
          const matchingMetadata = results.find(resultItem => resultItem.id === mixerAssetsData[key].id);

          if (matchingMetadata) {
            const stringifiedSharedData = JSON.stringify(mixerAssetsData[key]);
            const sanitizedSharedData = sanitize(stringifiedSharedData).replace(/<\/?[^>]+>/gi, '');

            mixerAssetsData[key] = {
              ...JSON.parse(sanitizedSharedData),
              ...matchingMetadata,
            };
          }
        });

        dispatch({
          type: UPDATE_MIXER_ASSETS,
          newMixerAssets: mixerAssetsData,
        });

        dispatch({
          type: IMPORT_MIX_SUCCESS,
        });

        openDefaultSnackbar('Mix imported successfully', 2000);
      })
      .catch(err => {
        dispatch({
          type: IMPORT_MIX_ERROR,
          err
        });

        openErrorSnackbar('Mix failed to import', 2000);
      });
  }

  // Return true or false depending on success
  function addMixerAsset(asset) {
    if (Object.keys(state.mixerAssets).length >= MIXER_CONFIG.sizeLimit) {
      openErrorSnackbar(`Mixer limit of ${MIXER_CONFIG.sizeLimit} sounds reached`);
      return false;
    }

    // Get next index position
    const objIndexes = Object
      .keys(state.mixerAssets)
      .sort((a, b) => { return state.mixerAssets[b] - state.mixerAssets[a]; })
      .map(Number);

    const nextIndex = objIndexes.length === 0 ? 1 : objIndexes[objIndexes.length - 1] + 1;

    const newMixerAssets = {
      ...state.mixerAssets,
      [nextIndex]: {
        ...asset,
        mixerSettings: {
          isLooping: false,
          volume: 1,
        },
      }
    };

    dispatch({
      type: UPDATE_MIXER_ASSETS,
      newMixerAssets,
    });

    openDefaultSnackbar('Sound added to mixer', 2000);
    return true;
  }

  function removeMixerAsset(assetIndex) {
    const newMixerAssets = state.mixerAssets;
    delete newMixerAssets[assetIndex];

    dispatch({
      type: UPDATE_MIXER_ASSETS,
      newMixerAssets,
    });

    openDefaultSnackbar('Sound removed from mixer', 2000);
  }

  function addPlayingMixerAsset() {
    dispatch({
      type: ADD_PLAYING_MIXER_ASSET,
    });
  }

  function removePlayingMixerAsset() {
    dispatch({
      type: REMOVE_PLAYING_MIXER_ASSET,
    });
  }

  function addReadyMixerAsset() {
    dispatch({
      type: ADD_READY_MIXER_ASSET,
    });
  }

  function removeReadyMixerAsset() {
    dispatch({
      type: REMOVE_READY_MIXER_ASSET,
    });
  }

  function setMixerPlaying(value) {
    dispatch({
      type: SET_MIXER_PLAYING,
      value,
    });
  }

  function setMixerPlaybackReset() {
    dispatch({
      type: SET_MIXER_PLAYBACK_RESET,
      mixerPlaybackReset: true,
    });
    setTimeout(() => {
      dispatch({
        type: SET_MIXER_PLAYBACK_RESET,
        mixerPlaybackReset: false,
      });
    }, 500);
  }

  function setMixerOpen(value) {
    dispatch({
      type: SET_MIXER_OPEN,
      value,
    });
  }

  function setMixerEditAsset(mixerId) {
    dispatch({
      type: SET_MIXER_EDIT_ASSET,
      mixerId,
    });
  }

  function setMixerAssetSettings(settings, id = state.mixerEditAssetId) {
    dispatch({
      type: SET_MIXER_ASSET_SETTINGS,
      settings,
      id,
    });
  }

  function toggleMixer(value) {
    dispatch({
      type: SET_MIXER_MODE,
      value
    });
  }

  const ctx = useMemo(() => ({
    state,
    dispatch,
    importMixerAssets,
    addMixerAsset,
    removeMixerAsset,
    setMixerPlaying,
    setMixerPlaybackReset,
    setMixerOpen,
    setMixerEditAsset,
    setMixerAssetSettings,
    toggleMixer,
    addPlayingMixerAsset,
    removePlayingMixerAsset,
    addReadyMixerAsset,
    removeReadyMixerAsset
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [state]);

  return (
    <MixerContext.Provider value={ctx}>
      {children}
    </MixerContext.Provider>
  );
};

MixerProvider.propTypes = {
  children: PropTypes.shape({})
};