import moment from 'moment';

export default (durationInMs) => {
  let format = 'm:ss';

  if ((durationInMs / (1000 * 60 * 60)).toFixed(1) >= 1) {
    format = 'h:mm:ss';
  }

  return moment.utc(durationInMs).format(format);
};
