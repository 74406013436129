import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from '../../Context/AppContext';

import BaseModal from '../BaseModal';
import { THEME_COLOURS } from '../../constants';
import GelIcon from '../GelIcon';

const SearchHelpModal = ({ setShowSearchHelpModal }) => {
  const { echoTrack } = useContext(AppContext);

  useEffect(() => {
    echoTrack('search_help_modal');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseModal labelId="searchhelp_modal_title" descriptionId="searchhelp_desc">
      <p className="sr-only" id="searchhelp_desc">Information regarding search techniques for the website.</p>
      <div className="overflow-y-auto text-left text-gray-900 md:h-full">
        <div className="p-6">
          <div className="flex items-center justify-between pb-3">
            <div className="flex flex-row items-center space-x-3">
              <span className="text-2xl font-bold" id="searchhelp_modal_title">Search Help</span>
            </div>
            <button
              type="button"
              className="p-2 rounded-md focus:outline-none focus:bg-gray-300 hover:bg-gray-300"
              onClick={() => setShowSearchHelpModal(false)}
              aria-label="Close"
            >
              <GelIcon name="close" fill={THEME_COLOURS.PRIMARY} />
            </button>
          </div>
          <ul className="px-6 text-sm list-disc list-outside md:text-base">
            <li className="mb-5">
              Use double quotes to search for exact phrases<br />
              <strong>Example: &quot;crowd cheering&quot;</strong>
            </li>
            <li className="mb-5">
              Use AND to return results which include all of the free text expressions<br />
              <strong>Example: bird AND crow</strong>
            </li>
            <li className="mb-5">
              Use NOT to exclude content<br />
              <strong>Example: game NOT football</strong><br />
            </li>
            <li className="mb-5">
              Use OR to return search results that include one or more of the specified free text expressions<br />
              <strong>Example: ambulance OR baby</strong>
            </li>
            <li className="mb-5">
              Specify part of a word, from the beginning of the word, followed by the wildcard operator *<br />
              <strong>Example: euro*</strong>
            </li>
          </ul>
        </div>
      </div>
    </BaseModal>
  );
};

SearchHelpModal.propTypes = {
  setShowSearchHelpModal: PropTypes.func.isRequired,
};

export default SearchHelpModal;
