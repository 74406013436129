import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function NotFound() {
  return (
    <>
      <Helmet title="404 Page not found | BBC Sound Effects" />
      <div className="max-w-screen-xl px-3 mx-auto">
        <div className="flex flex-col items-center py-48">
          <div className="text-5xl font-bold leading-tight text-gray-500">404</div>
          <h2 className="mb-3 text-4xl font-bold">Page not found</h2>
          <Link to="/" className="font-bold text-blue-800 hover:underline focus:underline">Return to home</Link>
        </div>
      </div>
    </>
  );
}

export default NotFound;
