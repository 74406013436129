import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../Context/AppContext';
import { MixerContext } from '../../Context/MixerContext';

import GelIcon from '../GelIcon';
import getQuerystring from '../../utilities/getQuerystring';
import modifyExistingQuerystring from '../../utilities/modifyExistingQuerystring';
import { SORT_OPTIONS, CATEGORY_LIST, DURATION_LIST, CONTINENT_LIST, THEME_COLOURS, RESULT_SIZE_CONFIG } from '../../constants';
import MixerToggle from '../MixerToggle';
import AppliedFilters from './AppliedFilters';
import { parseCategories, parseDurations, parseContinents, parseSources } from '../../utilities/queryParamParsers';

function WideFilter({ history }) {
  const {
    state: {
      resultsLength,
      categoryAggregations,
      continentAggregations,
      durationAggregations,
    },
    echoTrack
  } = useContext(AppContext);
  const { state: { mixerModeOn } } = useContext(MixerContext);

  // Store a reference to the page the user is on
  const pageContext = history.location.pathname.substring(1);

  const [filterMenuOpen, setFilterMenuOpen] = useState(true);
  const [sortByMenuOpen, setSortByMenuOpen] = useState(false);

  // Get search term from query string
  const parsedQuerystring = getQuerystring();

  // Use search term or default to empty
  const [categories, setCategories] = useState(parseCategories(parsedQuerystring.cat));
  const [durations, setDurations] = useState(parseDurations(parsedQuerystring.durations));
  const [sources, setSources] = useState(parseSources(parsedQuerystring.source));
  const [continents, setContinents] = useState(parseContinents(parsedQuerystring.continents));
  const [recordists, setRecordists] = useState(parsedQuerystring.recordist ? parsedQuerystring.recordist.split(',') : []);
  const [habitats, setHabitats] = useState(parsedQuerystring.habitat ? parsedQuerystring.habitat.split(',') : []);
  const [currentSortBy, setCurrentSortBy] = useState(parsedQuerystring.sortBy ? parsedQuerystring.sortBy : null);

  // Update the categories and durations when the URL params change
  useEffect(() => {
    setCategories(parseCategories(parsedQuerystring.cat));
    setDurations(parseDurations(parsedQuerystring.durations));
    setSources(parseSources(parsedQuerystring.source));
    setContinents(parseContinents(parsedQuerystring.continents));
    setRecordists(parsedQuerystring.recordist ? parsedQuerystring.recordist.split(',') : []);
    setHabitats(parsedQuerystring.habitat ? parsedQuerystring.habitat.split(',') : []);
  }, [
    parsedQuerystring.cat,
    parsedQuerystring.durations,
    parsedQuerystring.continents,
    parsedQuerystring.recordist,
    parsedQuerystring.source,
    parsedQuerystring.habitat
  ]);

  const [showCategories, toggleShowCategories] = useState(false);
  const [showDurations, toggleShowDurations] = useState(false);
  const [showContinents, toggleShowContinents] = useState(false);

  const performSearch = () => {
    echoTrack(pageContext, { action: 'desktop_filter_search' }, 'click');

    // This will reset other search filters
    history.push(`/${pageContext}?${modifyExistingQuerystring('cat', categories.join(','),
      modifyExistingQuerystring('durations', durations.join(','),
        modifyExistingQuerystring('continents', continents.join(','))))}`);
  };

  const toggleCategory = (category) => {
    echoTrack(pageContext, { action: 'desktop_filter_category_toggled', data: { category } }, 'click');

    if (categories.includes(category)) {
      setCategories(_.filter(categories, (cat) => cat !== category));
    } else {
      setCategories([
        category,
        ...categories,
      ]);
    }
  };

  const toggleDuration = (duration) => {
    echoTrack(pageContext, { action: 'desktop_filter_duration_toggled', data: { duration } }, 'click');

    if (durations.includes(duration)) {
      setDurations(_.filter(durations, (dur) => dur !== duration));
    } else {
      setDurations([
        duration,
        ...durations,
      ]);
    }
  };

  const toggleContinent = (continent) => {
    echoTrack(pageContext, { action: 'desktop_filter_continent_toggled', data: { continent } }, 'click');

    if (continents.includes(continent)) {
      setContinents(_.filter(continents, (cont) => cont !== continent));
    } else {
      setContinents([
        continent,
        ...continents,
      ]);
    }
  };

  const handleSort = (sortOption) => {
    echoTrack(pageContext, { action: 'desktop_filter_sort_changed', data: { sortOption } }, 'click');

    // Close dropdown
    setSortByMenuOpen(false);

    // Typically /search or /favourites
    const route = history.location.pathname;

    setCurrentSortBy(sortOption);

    // This will reset other search filters
    history.push(`${route}?${modifyExistingQuerystring('sortBy', sortOption)}`);
  };

  const closeFilterDropdowns = () => {
    toggleShowCategories(false);
    toggleShowDurations(false);
    toggleShowContinents(false);
    setSortByMenuOpen(false);
  };

  const applyFilters = () => {
    closeFilterDropdowns();
    performSearch();
  };

  const resetFilters = () => {
    echoTrack(pageContext, { action: 'desktop_filter_reset' }, 'click');

    closeFilterDropdowns();

    // This will reset other search filters
    history.push(`/${pageContext}?${modifyExistingQuerystring('cat', [], modifyExistingQuerystring('durations', [], modifyExistingQuerystring('continents', [], modifyExistingQuerystring('recordist', [], modifyExistingQuerystring('source', [], modifyExistingQuerystring('habitat', []))))))}`);
  };

  const cancelCategoryFilter = () => {
    setCategories(parsedQuerystring.cat ? parsedQuerystring.cat.split(',') : []);
    toggleShowCategories(false);
  };

  const cancelDurationFilter = () => {
    setDurations(parsedQuerystring.durations ? parsedQuerystring.durations.split(',') : []);
    toggleShowDurations(false);
  };

  const cancelContinentFilter = () => {
    setContinents(parsedQuerystring.continents ? parsedQuerystring.continents.split(',') : []);
    toggleShowContinents(false);
  };

  useEffect(() => {
    if (showCategories) {
      toggleShowDurations(false);
      toggleShowContinents(false);
      setSortByMenuOpen(false);
    }
  }, [showCategories]);

  useEffect(() => {
    if (showDurations) {
      toggleShowCategories(false);
      toggleShowContinents(false);
      setSortByMenuOpen(false);
    }
  }, [showDurations]);

  useEffect(() => {
    if (showContinents) {
      toggleShowCategories(false);
      toggleShowDurations(false);
      setSortByMenuOpen(false);
    }
  }, [showContinents]);

  useEffect(() => {
    if (sortByMenuOpen) {
      toggleShowCategories(false);
      toggleShowContinents(false);
      toggleShowDurations(false);
    }
  }, [sortByMenuOpen]);

  return (
    <div className={`${mixerModeOn ? 'bg-gray-700' : 'bg-gray-100'} w-full pb-3 transition-all duration-200 ease-in-out`}>
      {/* Clickaway Overlay */}
      {(showCategories || showContinents || showDurations || sortByMenuOpen) && (
        <button className="fixed top-0 left-0 w-full h-screen bg-transparent focus:outline-none" onClick={closeFilterDropdowns} />
      )}
      <div className="max-w-screen-xl px-3 pt-4 mx-auto">
        <div className="flex flex-row items-center justify-between">
          <h2 className="text-lg text-gray-900">
            {pageContext === 'search' ? (
              <span>
                Showing&nbsp;
                { RESULT_SIZE_CONFIG.sizeLimit < resultsLength && (
                  <span>top <strong>{RESULT_SIZE_CONFIG.sizeLimit}</strong> of&nbsp;</span>
                )}
                <strong>{resultsLength.toLocaleString()}</strong>
                {parsedQuerystring.q
                  ? (
                    <span> results for <strong>{parsedQuerystring.q}</strong></span>
                  )
                  : (
                    <span> results</span>
                  )}
              </span>
            )
              : (
                <span>
                  Your Favourites <strong>({resultsLength})</strong>
                </span>
              )}
          </h2>
          <div className="flex flex-row items-center">
            <MixerToggle />
            <div className="flex flex-row">
              <div className="relative px-2 lg:px-4">
                <button
                  type="button"
                  className={`focus:outline-none ${mixerModeOn ? 'bg-gray-700' : 'bg-gray-100'} transition-all duration-200 ease-in-out flex flex-row items-center justify-end px-3 py-1 w-full hover:underline focus:underline`}
                  onClick={() => setSortByMenuOpen(!sortByMenuOpen)}
                  aria-label={sortByMenuOpen ? 'Close sort by menu' : 'Open sort by menu'}
                >
                  <span className="px-2 font-bold text-gray-900 lg:text-xl">
                    Sort by
                  </span>
                  <GelIcon name={!sortByMenuOpen ? 'chevron-down' : 'chevron-up'} fill={THEME_COLOURS.PRIMARY} className="w-4 h-4" />
                </button>
                {
                  sortByMenuOpen && (
                    <div className="absolute z-10 block w-40 lg:w-full">
                      <div className="bg-white border-2">
                        <ul className="py-2">
                          {
                            SORT_OPTIONS.map(sortOption => (
                              <li key={sortOption.label}>
                                <button
                                  type="button"
                                  className={`focus:outline-none flex flex-row items-center justify-between w-full px-4 py-2 text-md hover:underline focus:underline${currentSortBy === sortOption.sort ? ' text-white bg-black font-bold' : ' text-gray-900'}`}
                                  onClick={() => handleSort(sortOption.sort)}
                                >
                                  <span className="truncate">{sortOption.label}</span>
                                </button>
                              </li>
                            ))
                          }
                        </ul>
                      </div>
                    </div>
                  )
                }
              </div>
              <button
                type="button"
                className={`focus:outline-none ${!filterMenuOpen ? 'bg-gray-100 border-gray-100' : 'bg-white border-gray-400 white-bottom-border'} ${mixerModeOn ? !filterMenuOpen ? 'bg-gray-700 border-gray-700' : 'bg-white' : ''} transition-all duration-200 ease-in-out border flex flex-row items-center px-3 py-1 hover:underline focus:underline`}
                onClick={() => setFilterMenuOpen(!filterMenuOpen)}
                aria-label={filterMenuOpen ? 'Close filter menu' : 'Open filter menu'}
              >
                <GelIcon name="filter" fill={THEME_COLOURS.PRIMARY} className="w-5 h-5" />
                <span className="px-2 font-bold text-gray-900 lg:text-xl">
                  Filter ({
                    categories.length
                    + durations.length
                    + continents.length
                    + recordists.length
                    + sources.length
                    + habitats.length
                  })
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {filterMenuOpen && (
        <div className="py-3 bg-white border-t border-b border-gray-400">
          <div className="flex flex-row items-center max-w-screen-xl px-3 mx-auto">
            <div className="flex flex-row w-5/6 mr-4 space-x-4">
              <div className="relative w-1/3">
                <button
                  type="button"
                  className="flex flex-row items-center justify-between w-full px-3 py-2 bg-white border-2 border-gray-400 focus:outline-none hover:border-blue-800 focus:border-blue-800"
                  onClick={() => toggleShowCategories(!showCategories)}
                >
                  <span className="text-base font-bold text-gray-900 lg:text-lg">
                    Categories ({categories.length})
                  </span>
                  <GelIcon className="w-4 h-4" name={showCategories ? 'chevron-up' : 'chevron-down'} fill={THEME_COLOURS.SECONDARY} />
                </button>
                {
                  showCategories && (
                    <div className="absolute z-10 block w-64 lg:w-full">
                      <div className="bg-white border-b-2 border-l-2 border-r-2 border-gray-400">
                        <ul className="py-2 overflow-y-auto scrolling-touch h-72">
                          {
                            CATEGORY_LIST.length && CATEGORY_LIST.map((category) => (

                              <li key={category.name}>
                                <button
                                  type="button"
                                  role="checkbox"
                                  aria-checked={categories.includes(category.name)}
                                  onClick={() => toggleCategory(category.name)}
                                  className="flex flex-row items-center justify-between w-full px-4 py-2 focus:outline-none text-md hover:underline focus:underline"
                                >
                                  <span className="text-gray-900 truncate">{category.name.replace('_', ' ')}</span>
                                  <div className="flex flex-row items-center">
                                    <small className="mr-2 text-gray-900">{categoryAggregations[category.name] && `(${categoryAggregations[category.name].doc_count})`}</small>
                                    {categories.includes(category.name)
                                      ? (
                                        <div className="w-6 h-6 border-2 border-gray-900">
                                          <GelIcon className="z-20" name="check" fill={THEME_COLOURS.PRIMARY} />
                                        </div>
                                      )
                                      : (
                                        <div className="w-6 h-6 border-2 border-gray-900" />
                                      )}
                                  </div>
                                </button>
                              </li>
                            ))
                          }
                        </ul>
                        <div className="flex w-full">
                          <div className="w-1/2">
                            <button type="button" className="items-center w-full h-full p-4 font-bold text-white bg-gray-900 focus:outline-none hover:opacity-75 focus:opacity-75" onClick={cancelCategoryFilter}>
                              Cancel
                            </button>
                          </div>
                          <div className="w-1/2">
                            <button type="button" className="items-center w-full h-full p-4 font-bold text-white bg-teal-700 focus:outline-none hover:opacity-75 focus:opacity-75" onClick={applyFilters}>
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
              <div className="relative w-1/3">
                <button
                  type="button"
                  className="flex flex-row items-center justify-between w-full px-3 py-2 bg-white border-2 border-gray-400 focus:outline-none hover:border-blue-800 focus:border-blue-800"
                  onClick={() => toggleShowDurations(!showDurations)}
                >
                  <span className="text-base font-bold text-gray-900 lg:text-lg">
                    Duration ({durations.length})
                  </span>
                  <GelIcon className="w-4 h-4" name={showDurations ? 'chevron-up' : 'chevron-down'} fill={THEME_COLOURS.SECONDARY} />
                </button>
                {
                  showDurations && (
                    <div className="absolute z-10 block w-64 lg:w-full">
                      <div className="bg-white border-b-2 border-l-2 border-r-2 border-gray-400">
                        <ul className="py-2">
                          {
                            DURATION_LIST.map((duration) => (
                              <li key={duration.query}>
                                <button
                                  type="button"
                                  role="checkbox"
                                  aria-checked={durations.includes(duration.query)}
                                  onClick={() => toggleDuration(duration.query)}
                                  className="flex flex-row items-center justify-between w-full px-4 py-2 focus:outline-none text-md hover:underline focus:underline"
                                >
                                  <span className="text-gray-900 truncate">{duration.text}</span>
                                  <div className="flex flex-row items-center">
                                    <small className="mr-2 text-gray-900">{durationAggregations[duration.query] && `(${durationAggregations[duration.query].doc_count})`}</small>
                                    {durations.includes(duration.query)
                                      ? (
                                        <div className="w-6 h-6 border-2 border-gray-900">
                                          <GelIcon className="z-20" name="check" fill={THEME_COLOURS.PRIMARY} />
                                        </div>
                                      )
                                      : (
                                        <div className="w-6 h-6 border-2 border-gray-900" />
                                      )}
                                  </div>
                                </button>
                              </li>
                            ))
                          }
                        </ul>
                        <div className="flex w-full">
                          <div className="w-1/2">
                            <button type="button" className="items-center w-full h-full p-4 font-bold text-white bg-gray-900 focus:outline-none hover:opacity-75 focus:opacity-75" onClick={cancelDurationFilter}>
                              Cancel
                            </button>
                          </div>
                          <div className="w-1/2">
                            <button type="button" className="items-center w-full h-full p-4 font-bold text-white bg-teal-700 focus:outline-none hover:opacity-75 focus:opacity-75" onClick={applyFilters}>
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
              <div className="relative w-1/3">
                <button
                  type="button"
                  className="flex flex-row items-center justify-between w-full px-3 py-2 bg-white border-2 border-gray-400 focus:outline-none hover:border-blue-800 focus:border-blue-800"
                  onClick={() => toggleShowContinents(!showContinents)}
                >
                  <span className="text-base font-bold text-gray-900 lg:text-lg">
                    Continents ({continents.length})
                  </span>
                  <GelIcon className="w-4 h-4" name={showContinents ? 'chevron-up' : 'chevron-down'} fill={THEME_COLOURS.SECONDARY} />
                </button>
                {
                  showContinents && (
                    <div className="absolute z-10 block w-64 lg:w-full">
                      <div className="bg-white border-b-2 border-l-2 border-r-2 border-gray-400">
                        <ul className="py-2">
                          {
                            CONTINENT_LIST.map((continent) => (
                              <li key={continent}>
                                <button
                                  type="button"
                                  role="checkbox"
                                  aria-checked={continents.includes(continent)}
                                  onClick={() => toggleContinent(continent)}
                                  className="flex flex-row items-center justify-between w-full px-4 py-2 focus:outline-none text-md hover:underline focus:underline"
                                >
                                  <span className="text-gray-900 truncate">{continent}</span>
                                  <div className="flex flex-row items-center">
                                    <small className="mr-2 text-gray-900">{continentAggregations[continent] && `(${continentAggregations[continent].doc_count})`}</small>
                                    {continents.includes(continent)
                                      ? (
                                        <div className="w-6 h-6 border-2 border-gray-900">
                                          <GelIcon className="z-20" name="check" fill={THEME_COLOURS.PRIMARY} />
                                        </div>
                                      )
                                      : (
                                        <div className="w-6 h-6 border-2 border-gray-900" />
                                      )}
                                  </div>
                                </button>
                              </li>
                            ))
                          }
                        </ul>
                        <div className="flex w-full">
                          <div className="w-1/2">
                            <button type="button" className="items-center w-full h-full p-4 font-bold text-white bg-gray-900 focus:outline-none hover:opacity-75 focus:opacity-75" onClick={cancelContinentFilter}>
                              Cancel
                            </button>
                          </div>
                          <div className="w-1/2">
                            <button type="button" className="items-center w-full h-full p-4 font-bold text-white bg-teal-700 focus:outline-none hover:opacity-75 focus:opacity-75" onClick={applyFilters}>
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
            <div className="w-1/5 lg:w-1/6">
              <button
                type="button"
                className="flex flex-row items-center justify-center w-full px-2 py-2 font-bold bg-blue-800 border-0 focus:outline-none hover:opacity-75 focus:opacity-75"
                onClick={resetFilters}
              >
                <GelIcon className="w-5 h-5" name="refresh" fill="white" />
                <span className="pl-2 text-base text-white lg:text-lg">Reset Filters</span>
              </button>
            </div>
          </div>
          <AppliedFilters />
        </div>
      )}
    </div>
  );
}

WideFilter.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    listen: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  })
};

export default withRouter(WideFilter);
