import React from 'react';
import PropTypes from 'prop-types';

import BaseModal from '../../Components/BaseModal'
import GelIcon from '../../Components/GelIcon'
import { THEME_COLOURS } from '../../constants'
import { Link } from 'react-router-dom';

const ProSoundInfoModal = ({ setShowProSoundInfoModal }) => {
  return (
    <BaseModal labelId="prosound_modal_title" descriptionId="prosound_modal_desc">
      <div className="text-left">
        <div className="flex items-center justify-between px-6 py-8 sm:px-8">
          <div className="flex flex-row items-center space-x-3">
            <GelIcon name="help" fill={THEME_COLOURS.PRIMARY} className="w-4 h-4" />
            <span className="text-xl font-bold text-gray-900 md:text-2xl" id="prosound_modal_title">Buying sounds</span>
          </div>
          <button type="button" className="p-2 rounded-md focus:outline-none focus:bg-gray-300 hover:bg-gray-300" onClick={() => setShowProSoundInfoModal(false)} aria-label="Close">
            <GelIcon name="close" fill={THEME_COLOURS.PRIMARY} />
          </button>
        </div>
        <div className="px-6 text-gray-900 sm:px-8">
          <p id="prosound_modal_desc">
            If you are using <strong>any</strong> of the sounds on the BBC Sound Effects site
            <strong> commercially</strong>, you must purchase the sound from Pro Sound Effects.
          </p>
          <br />
          <p>
            To find out more, check out our <Link to="/licensing" className="font-semibold text-blue-800 outline-none hover:underline focus:underline">licences &amp; usage.</Link>
          </p>
        </div>
        <div className="flex justify-center w-full mt-10 md:justify-end md:pb-8 md:px-6">
          <button
            className="w-full px-4 py-3 font-semibold text-white bg-teal-700 md:w-1/3 focus:outline-none hover:opacity-75 focus:opacity-75"
            onClick={() => setShowProSoundInfoModal(false)}
          >
            Okay, got it
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

ProSoundInfoModal.propTypes = {
  setShowProSoundInfoModal: PropTypes.func.isRequired,
};

export default ProSoundInfoModal;