import React, { useState, useEffect, useContext } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookie from 'js-cookie';

import { AppContext } from '../../Context/AppContext';

import BaseModal from '../../Components/BaseModal';
import GelIcon from '../../Components/GelIcon';
import { DEFAULT_PAGE_ROUTE, COOKIE_RATING_SUBMITTED, RATING_MODAL_TIMER, RATING_VALUES, THEME_COLOURS } from '../../constants';

const RatingButton = ({ icon, title, onClick }) => (
  <button
    className="transition-all duration-200 ease-in-out rounded-full hover:bg-yellow-400 focus:bg-yellow-400"
    onClick={onClick}
    aria-label={icon}
    title={title}
  >
    <GelIcon name={icon} viewBox="0 0 90 90" className="w-20 h-20" />
  </button>
);

RatingButton.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func
};

function RatingModal({ history }) {
  // Store a reference to the page the user is on
  const pageContext = history.location.pathname;
  const [ratingSubmitted, setRatingSubmitted] = useState(false);
  const { submitRating, toggleRatingModal, state: { showRatingModal } } = useContext(AppContext);

  useEffect(() => {
    const showModalTimeout = setTimeout(() => {
      // Show the rating modal if cookie is not present
      !Cookie.get(COOKIE_RATING_SUBMITTED) && toggleRatingModal(true);
    }, RATING_MODAL_TIMER);

    return () => clearTimeout(showModalTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRatingSubmit = (rating) => {
    submitRating(rating);
    setRatingSubmitted(true);
  };

  if (pageContext !== DEFAULT_PAGE_ROUTE || !showRatingModal) return null;

  return (
    <BaseModal autofocus={true} labelId="rating_modal_title" descriptionId="rating_modal_desc">
      <p className="sr-only" id="rating_modal_desc">
        A dialog containing options for providing your rating for this website.
        There are three rating options to choose from, or an option to skip providing a rating.
      </p>
      <div className="px-6 py-8 text-left sm:px-8">
        {
          !ratingSubmitted
            ? (
              <>
                <p className="text-xl font-bold text-center sm:text-2xl" id="rating_modal_title">Are you enjoying Sound Effects?</p>
                <div className="flex flex-row justify-around my-8">
                  {
                    RATING_VALUES.map(rating => (
                      <RatingButton
                        key={rating.value}
                        icon={rating.icon}
                        title={rating.title}
                        onClick={() => handleRatingSubmit(rating.value)}
                      />
                    ))
                  }
                </div>
                <p className="text-center">
                  <button className="font-semibold text-blue-800 underline hover:opacity-75 focus:opacity-75" data-autofocus onClick={() => toggleRatingModal(false)}>Skip</button>
                </p>
              </>
            ) : (
              <>
                <div className="flex flex-row items-center justify-between mb-4">
                  <p className="text-xl font-bold sm:text-2xl">Feedback Submitted</p>
                  <button type="button" className="p-2 rounded-md focus:outline-none focus:bg-gray-300 hover:bg-gray-300" aria-label="Close" onClick={() => toggleRatingModal(false)}>
                    <GelIcon name="close" fill={THEME_COLOURS.PRIMARY} />
                  </button>
                </div>
                <p className="mb-2">Thank you for submitting your rating! As we continue to improve the site,
                  the option to provide a rating may appear again for you in the future.</p>
                <p>Let us know if you have any comments using our <Link onClick={() => toggleRatingModal(false)} className="font-semibold text-teal-700 underline hover:opacity-75 focus:opacity-75" to="/feedback">Feedback form</Link>.</p>
              </>
            )
        }
      </div>
    </BaseModal>
  );
}

RatingModal.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  })
};

export default withRouter(RatingModal);
