import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { THEME_COLOURS } from '../../constants';

function SMPPlayer({ vpid, thumbnail }) {
  const playerElement = useRef(null);

  const buildPlayerConfig = () => ({
    superResponsive: true,
    product: 'news',
    ui: {
      colour: THEME_COLOURS.TERTIARY,
    },
    playlistObject: {
      items: [{ vpid }],
      holdingImageURL: thumbnail,
    },
  });

  const loadBump = () => {
    window.require(['bump-4'], (Bump) => {
      const playerConfig = buildPlayerConfig();
      const smp = Bump.player(playerElement.current, playerConfig);

      smp.load();
    });
  };

  useEffect(() => {
    loadBump();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={playerElement} />
  );
}

SMPPlayer.propTypes = {
  vpid: PropTypes.string,
  thumbnail: PropTypes.string
};

export default SMPPlayer;
