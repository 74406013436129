import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AppContext } from '../../Context/AppContext';

function PlaybackDelayInput({ playbackTiming, setPlaybackTiming, invalidDelay, displayType }) {
  const { echoTrack } = useContext(AppContext);
  const delay = (playbackTiming && playbackTiming.delay) || '';

  const handleDelayChange = (seconds) => {
    echoTrack('mixer', { action: 'mixer_delay' }, 'click');

    if (seconds) {
      setPlaybackTiming({
        ...playbackTiming,
        delay: seconds.replace(/\D/g, ''),
      });
    } else {
      setPlaybackTiming({
        ...playbackTiming,
        delay: null,
      });
    }
  };

  return (
    <>
      <div className="mr-4 font-semibold lg:mr-8">Delay Start</div>
      <div className="flex flex-row items-center space-x-6">
        <div className={`relative flex flex-row items-center px-4 py-2 border-2 border-${invalidDelay ? 'red-500' : 'white'}`}>
          {
            invalidDelay && <span className="absolute left-0 w-full text-xs font-bold text-red-500" style={{ top: -22 }}>Please enter a whole number</span>
          }
          <label className="sr-only" htmlFor={`audio-delay-${displayType}`}>Delay</label>
          <input
            type="tel"
            size="4"
            id={`audio-delay-${displayType}`}
            name={`audio-delay-${displayType}`}
            className="w-8 text-right text-white bg-transparent lg:text-sm"
            placeholder="0"
            value={delay}
            onChange={e => handleDelayChange(e.target.value)}
            maxLength="4"
          />
          <span className="mx-2 text-sm font-semibold">seconds</span>
        </div>
      </div>
    </>
  );
}

PlaybackDelayInput.propTypes = {
  playbackTiming: PropTypes.shape({
    delay: PropTypes.string
  }),
  setPlaybackTiming: PropTypes.func,
  invalidDelay: PropTypes.bool,
  displayType: PropTypes.string
};

PlaybackDelayInput.defaultProps = {
  playbackTiming: {
    delay: 0,
  },
};

export default PlaybackDelayInput;
