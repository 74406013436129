import { parse, stringify } from 'query-string';

export default (key, value, base = window.location.search) => {
  // Removing '?' from querystring if it is the first character
  const parsed = parse(base.replace(/^\?+/, ''));

  if (value) {
    parsed[key] = value;
  } else {
    delete parsed[key];
  }

  return stringify(parsed);
};
