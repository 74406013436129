import React, { Component } from 'react';
import PropTypes from 'prop-types';

import GelIcon from '../GelIcon';
import { THEME_COLOURS } from '../../constants';

class GlobalErrorCatcher extends Component {
  state = { capturedError: false };

  componentDidCatch(capturedError) {
    this.setState({ capturedError });
  }

  render() {
    const { capturedError } = this.state;
    const { children } = this.props;

    if (!capturedError) return children;

    return (
      <div className="flex flex-col items-center justify-center w-screen h-screen p-4 bg-gray-100">
        <GelIcon name="sound-on-off" fill={THEME_COLOURS.PRIMARY} className="w-20 h-20" />
        <h1 className="my-5 text-xl font-bold text-center text-gray-900">Sound Effects has encountered an error and is unable to continue</h1>
        <div>
          <button
            type="button"
            className="px-6 py-3 font-bold text-center text-white bg-teal-700 sm:w-auto hover:opacity-75 focus:opacity-75"
            onClick={() => window.location.reload()}
          >
            Reload application
          </button>
        </div>
      </div>
    );
  }
}

GlobalErrorCatcher.propTypes = {
  children: PropTypes.shape({})
};

export default GlobalErrorCatcher;
