import React, { useState, useRef, useEffect, useContext } from 'react';
import WaveSurfer from 'wavesurfer.js';
import MinimapPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.minimap.min.js';
import PropTypes from 'prop-types';

import { AppContext } from '../../Context/AppContext';
import { MixerContext } from '../../Context/MixerContext';

import GelIcon from '../GelIcon';
import ShareModal from '../ShareModal';
import { MEDIA_LOW_QUALITY_URL, WAVEFORM_URL, THEME_COLOURS } from '../../constants';
import formatAssetDuration from '../../utilities/formatAssetDuration';

const PlaybackTimer = ({ isSticky, progress, time }) => {
  const leftOffset = time.length === 7 ? '30px' : time.length === 5 ? '25px' : '20px';

  return (
    <div className={`absolute z-10 px-2 text-xs font-bold text-${!isSticky ? 'blue-800' : 'white'} ${!isSticky ? 'bg-white' : 'bg-gray-900'}`}
      style={{ top: !isSticky ? '-20px' : '-10px', left: `calc(${(progress * 100)}% - ${leftOffset}) ` }}>{time}</div>
  );
};

PlaybackTimer.propTypes = {
  isSticky: PropTypes.bool,
  progress: PropTypes.number,
  time: PropTypes.string
};

function AudioPlayer({
  asset,
  isLooping,
  setIsLooping,
  favourited,
  handleFavouriteClick,
  showDownloadPopover,
  setShowDownloadPopover,
}) {
  const {
    state: { selectedAsset, autoplay, results, autoplaySupported, },
    echoTrack,
    setSelectedAsset,
    toggleAutoplay,
  } = useContext(AppContext);
  const { state: { mixerModeOn } } = useContext(MixerContext);
  const [wavesurferPlayer, setWavesurferPlayer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [mobileProgress, setMobileProgress] = useState(0);
  const [playbackTime, setPlaybackTime] = useState(0);
  const [showShareModal, setShowShareModal] = useState(false);

  const waveContainerRef = useRef();
  const miniWaveContainerRef = useRef();

  useEffect(() => {
    if (!wavesurferPlayer) {
      const wavesurferInstance = WaveSurfer.create({
        container: waveContainerRef.current,
        waveColor: THEME_COLOURS.DARK_GREY,
        progressColor: THEME_COLOURS.TERTIARY,
        cursorColor: THEME_COLOURS.TERTIARY,
        barWidth: 2,
        barMinHeight: 1,
        responsive: true,
        hideScrollbar: true,
        height: 60,
        loopSelection: true,
        backend: "MediaElement",
        showTime: true,
        cursorWidth: 2,
        plugins: [
          MinimapPlugin.create({
            container: miniWaveContainerRef.current,
            waveColor: THEME_COLOURS.NAVY_GREY,
            progressColor: THEME_COLOURS.GREY,
            cursorColor: THEME_COLOURS.GREY,
            barWidth: 2,
            barMinHeight: 1,
            responsive: true,
            height: 60,
          }),
        ]
      });

      wavesurferInstance.on('ready', () => {
        setIsLoading(false);
      });

      wavesurferInstance.on('seek', progress => {
        setMobileProgress(progress);
        setPlaybackTime(Math.floor(wavesurferInstance.getCurrentTime() * 1000));
      });

      // Track progress
      wavesurferInstance.on('audioprocess', secondsElapsed => {
        setMobileProgress(secondsElapsed / wavesurferInstance.getDuration());
        setPlaybackTime(Math.floor(secondsElapsed * 1000));
      });

      // TODO - There may be a better way of doing this
      fetch(`${WAVEFORM_URL}/${asset.file.small.name}.json`)
        .then(res => {
          return res.json();
        })
        .then(peaks => {
          wavesurferInstance.load(`${MEDIA_LOW_QUALITY_URL}/${asset.file.small.name}.mp3`, peaks.data, 'metadata');
        })
        .then(() => {
          // Need this to render the minimap
          wavesurferInstance.fireEvent('waveform-ready');
        })
        .catch(e => {
          console.error('error', e);
        });

      setWavesurferPlayer(wavesurferInstance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update event handlers if looping prop changes
  useEffect(() => {
    if (wavesurferPlayer && asset) {
      wavesurferPlayer.un('finish');

      wavesurferPlayer.on('finish', () => {
        if (isLooping) {
          wavesurferPlayer.play();
          echoTrack('audioplayer', { action: 'complete_media', data: { assetId: asset.id } }, 'click');
        } else {
          wavesurferPlayer.pause();
          echoTrack('audioplayer', { action: 'complete_media', data: { assetId: asset.id } }, 'click');
          setIsPlaying(false);

          // Play next if autoplay is on and a next exists
          const currentAssetIndex = results.findIndex(result => result.id === asset.id);
          const nextAsset = results[currentAssetIndex + 1];

          if (nextAsset && autoplay) {
            setSelectedAsset(nextAsset);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wavesurferPlayer, isLooping, asset, echoTrack]);

  // Listen to changes in selectedAsset
  useEffect(() => {
    if (wavesurferPlayer) {
      if ((selectedAsset && selectedAsset.id !== asset.id) || !selectedAsset) {
        try {
          wavesurferPlayer.stop();
        } catch (error) {
          console.error(error.message); // Prevents the page from crashing, but does not solve the 'currentTime' issue
        }
        setIsPlaying(false);
      }

      // Play if selected asset is set to this one, i.e. from autoplay
      if (selectedAsset && selectedAsset.id === asset.id && !isPlaying) {
        setIsPlaying(true);
        wavesurferPlayer.play();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAsset]);

  // Perform echo track actions on button press - seeking in audio player triggers play_media actions we may not want
  const handlePlayClick = () => {
    if (!selectedAsset || selectedAsset.id !== asset.id) {
      setSelectedAsset(asset);
    }

    if (isPlaying) {
      setIsPlaying(false);
      wavesurferPlayer.pause();
      echoTrack('audioplayer', { action: 'pause_media', data: { assetId: asset.id } }, 'click');
    } else {
      setIsPlaying(true);
      wavesurferPlayer.play();
      echoTrack('audioplayer', { action: 'play_media', data: { assetId: asset.id } }, 'click');
    }
  };

  const scrollToAsset = () => {
    setTimeout(() => {
      document.getElementById(selectedAsset.id).scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }, 200);
  };

  const handleProgressClick = (e) => {
    echoTrack('audioplayer', { action: 'playback_seek', data: { assetId: asset.id } }, 'click');

    wavesurferPlayer.seekTo(e.clientX / window.innerWidth);
    setMobileProgress(e.clientX / window.innerWidth);
  };

  const handleDownloadClick = () => {
    echoTrack('audioplayer', { action: 'download_asset', data: { assetId: asset.id } }, 'click');

    scrollToAsset();
    setShowDownloadPopover(!showDownloadPopover);
  };

  const stopAndHidePlayer = () => {
    if (wavesurferPlayer && wavesurferPlayer.isPlaying()) {
      wavesurferPlayer.stop();
      setIsPlaying(false);
    }
    setSelectedAsset(null);
  };

  // Listen for changes in mixer mode
  // If it changes, the waveform needs to be redrawn to fit container properly
  // Stop all playback of other sounds when going into Mixer mode
  useEffect(() => {
    if (wavesurferPlayer) {
      wavesurferPlayer.setHeight(60);
    }
    if (mixerModeOn) {
      stopAndHidePlayer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixerModeOn, wavesurferPlayer]);

  return (
    <>
      {/* Asset player */}
      <div className="flex flex-row-reverse items-center justify-between w-full lg:flex-row lg:space-x-4">
        <button
          type="button"
          title={!isPlaying ? 'Play sound' : 'Pause sound'}
          className={`p-2 ml-4 rounded-md focus:outline-none lg:ml-0 hover:bg-gray-300 focus:bg-gray-300 ${isLoading ? 'cursor-not-allowed' : ''}`}
          onClick={handlePlayClick}
          aria-label={isPlaying ? 'Pause' : 'Play'}
          disabled={isLoading}
        >
          <GelIcon name={!isPlaying ? 'play' : 'pause'} className="w-5 h-5" fill={isLoading ? 'grey' : THEME_COLOURS.SECONDARY} />
        </button>
        <div className="relative z-0 w-full h-full">
          {isLoading && (
            <div className="absolute w-full h-full">
              <GelIcon name="loading" fill={THEME_COLOURS.DARK_GREY} className="w-8 h-8 mx-auto my-3 opacity-25 animate-spin" />
            </div>
          )}
          <div ref={waveContainerRef}>
            {
              (playbackTime !== 0) && (
                <PlaybackTimer progress={mobileProgress} time={formatAssetDuration(playbackTime)} />
              )
            }
          </div>
        </div>
        <div className="px-2 text-sm lg:p-0">
          {formatAssetDuration(asset.duration)}
        </div>
        <button
          type="button"
          className="hidden p-2 rounded-md focus:outline-none hover:bg-gray-300 focus:bg-gray-300 lg:block"
          onClick={() => setIsLooping(!isLooping)}
          aria-label={isLooping ? 'Disable looping' : 'Enable looping'}
          title={isLooping ? 'Disable looping' : 'Enable looping'}
        >
          <GelIcon name={isLooping ? 'movement-on' : 'movement-off'} fill={isLooping ? THEME_COLOURS.TERTIARY : THEME_COLOURS.DARK_GREY} className="w-6 h-6" />
        </button>
      </div>

      {/* Sticky player, don't show during mixer mode */}
      <div
        className="fixed left-0 right-0 z-40 w-full text-white transition-all duration-500 ease-in-out bg-gray-900"
        style={{
          bottom: selectedAsset && selectedAsset.id === asset.id && !mixerModeOn ? 0 : -150,
          visibility: selectedAsset && selectedAsset.id === asset.id && !mixerModeOn ? "visible" : "hidden"
        }}
      >
        <div className="flex flex-col max-w-screen-xl mx-auto lg:p-3">
          <div className="w-full lg:px-6">
            <button
              type="button"
              title="Scrub"
              aria-label="Move play head"
              className="absolute top-0 left-0 right-0 w-full h-2 bg-gray-500 focus:outline-none lg:hidden"
              onClick={handleProgressClick}
            >
              <div className="absolute top-0 left-0 h-full bg-blue-800" style={{ width: `${mobileProgress * 100}%` }} />
            </button>
            <div className="flex flex-row-reverse items-center justify-between w-full p-3 lg:p-0 lg:flex-row">
              <div className="flex flex-row-reverse items-center w-1/3 lg:w-1/2 lg:flex-row">
                <button
                  type="button"
                  title={!isPlaying ? 'Play sound' : 'Pause sound'}
                  className="p-2 rounded-md focus:outline-none hover:bg-gray-800 focus:bg-gray-800 lg:mr-4"
                  onClick={handlePlayClick}
                  aria-label={!isPlaying ? 'Play' : 'Pause'}
                >
                  <GelIcon name={!isPlaying ? 'play' : 'pause'} className="w-5 h-5" fill="white" />
                </button>
                <div className="relative hidden w-full lg:block" ref={miniWaveContainerRef}>
                  {
                    (playbackTime !== 0) &&
                    (<PlaybackTimer
                      isSticky={true}
                      progress={mobileProgress}
                      time={formatAssetDuration(playbackTime)}
                    />)
                  }
                </div>
                <div className="hidden px-4 text-sm md:block">
                  {formatAssetDuration(asset.duration)}
                </div>
                {autoplaySupported && (
                  <button
                    type="button"
                    onClick={() => toggleAutoplay()}
                    className="p-2 rounded-md focus:outline-none hover:bg-gray-800 focus:bg-gray-800 lg:hidden"
                    aria-label={autoplay ? 'Disable autoplay' : 'Enable autoplay'}
                    title={autoplay ? 'Autoplay is ON' : 'Autoplay is OFF'}
                  >
                    <GelIcon
                      name={`continuous-play${autoplay ? '' : '-off'}`}
                      className="w-6 h-6 lg:w-5 lg:h-5"
                      fill={autoplay ? 'white' : THEME_COLOURS.DARK_GREY}
                      viewBox={autoplay ? '0 0 32 32' : '0 0 30 30'}
                    />
                  </button>
                )}
                <button
                  type="button"
                  className="p-2 rounded-md focus:outline-none hover:bg-gray-800 focus:bg-gray-800"
                  onClick={() => setIsLooping(!isLooping)}
                  aria-label={isLooping ? 'Disable looping' : 'Enable looping'}
                  title={isLooping ? 'Disable looping' : 'Enable looping'}
                >
                  <GelIcon name={isLooping ? 'movement-on' : 'movement-off'} fill={isLooping ? "white" : THEME_COLOURS.DARK_GREY} className="w-6 h-6" />
                </button>
              </div>
              <div className="flex lg:w-1/2">
                <div className="flex flex-row items-center justify-center lg:space-x-4 lg:w-1/2">
                  <button
                    type="button"
                    className="hidden p-2 rounded-md focus:outline-none hover:bg-gray-800 focus:bg-gray-800 lg:block"
                    onClick={() => setShowShareModal(true)}
                    aria-label="Share"
                    title="Share"
                  >
                    <GelIcon name="share" fill="white" className="w-6 h-6 lg:w-5 lg:h-5" />
                  </button>
                  <button
                    type="button"
                    onClick={handleFavouriteClick}
                    className="p-2 mr-8 rounded-md focus:outline-none lg:mr-0 hover:bg-gray-800 focus:bg-gray-800"
                    aria-label={favourited ? 'Remove from favourites' : 'Add to favourites'}
                    title={favourited ? 'Remove from favourites' : 'Add to favourites'}
                  >
                    <GelIcon name={favourited ? 'love-filled' : 'love'} fill="white" className="w-6 h-6 lg:w-5 lg:h-5" />
                  </button>
                  <button
                    type="button"
                    onClick={handleDownloadClick}
                    className="p-2 rounded-md focus:outline-none hover:bg-gray-800 focus:bg-gray-800"
                    aria-label={showDownloadPopover ? 'Hide download options' : 'Show download options'}
                    title={showDownloadPopover ? 'Hide download options' : 'Show download options'}
                  >
                    <GelIcon name="download" className="w-6 h-6 lg:w-5 lg:h-5" fill="white" />
                  </button>
                  {autoplaySupported && (
                    <button
                      type="button"
                      onClick={() => toggleAutoplay()}
                      className="hidden p-2 rounded-md focus:outline-none hover:bg-gray-800 focus:bg-gray-800 lg:block"
                      aria-label={autoplay ? 'Disable autoplay' : 'Enable autoplay'}
                      title={autoplay ? 'Autoplay is ON' : 'Autoplay is OFF'}
                    >
                      <GelIcon
                        name={`continuous-play${autoplay ? '' : '-off'}`}
                        className="w-6 h-6 lg:w-5 lg:h-5"
                        fill={autoplay ? 'white' : THEME_COLOURS.DARK_GREY}
                        viewBox={autoplay ? '0 0 32 32' : '0 0 30 30'}
                      />
                    </button>
                  )}
                </div>
                <div className="flex-row items-center hidden w-1/2 lg:flex">
                  <button
                    type="button"
                    className="w-full px-4 py-2 font-bold bg-teal-700 focus:outline-none hover:opacity-75 focus:opacity-75"
                    onClick={scrollToAsset}
                    aria-label="Show in search results"
                  >
                    Show in search
                  </button>
                </div>
              </div>
            </div>
            <p className="mx-3 my-2 truncate lg:mx-0">{selectedAsset ? selectedAsset.description : ''}</p>
            <div className="flex w-full lg:hidden">
              <div className="w-1/2">
                <button
                  type="button"
                  className="items-center w-full h-full px-4 py-2 text-white bg-gray-900 focus:outline-none hover:opacity-75 focus:opacity-75"
                  onClick={stopAndHidePlayer}
                  aria-label="Hide player"
                >
                  Hide Player
                </button>
              </div>
              <div className="w-1/2">
                <button
                  type="button"
                  className="items-center w-full h-full px-4 py-2 text-white bg-teal-700 focus:outline-none hover:opacity-75 focus:opacity-75"
                  onClick={scrollToAsset}
                  aria-label="Show in search results"
                >
                  Show in search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        showShareModal && (
          <ShareModal asset={asset} setShowShareModal={setShowShareModal} />
        )
      }
    </>
  );
}

AudioPlayer.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.string,
    duration: PropTypes.number,
    file: PropTypes.shape({
      small: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  }).isRequired,
  isLooping: PropTypes.bool.isRequired,
  setIsLooping: PropTypes.func.isRequired,
  favourited: PropTypes.bool.isRequired,
  handleFavouriteClick: PropTypes.func.isRequired,
  showDownloadPopover: PropTypes.bool.isRequired,
  setShowDownloadPopover: PropTypes.func.isRequired,
};

export default AudioPlayer;
