import React from "react";
import SnackbarProvider from 'react-simple-snackbar';
import Router from "./Router";
import GlobalErrorCatcher from './Components/GlobalErrorCatcher';
import { AppProvider } from "./Context/AppContext";
import { FavouritesProvider } from "./Context/FavouritesContext";
import { MixerProvider } from "./Context/MixerContext";

export default function App() {
  return (
    <GlobalErrorCatcher>
      <SnackbarProvider>
        <FavouritesProvider>
          <MixerProvider>
            <AppProvider>
              <Router />
            </AppProvider>
          </MixerProvider>
        </FavouritesProvider>
      </SnackbarProvider>
    </GlobalErrorCatcher>
  );
}
