import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// Rating modal
import RatingModal from './Components/RatingModal';

// Navbar
import Navbar from './Components/Navbar';
import PageFooter from './Components/PageFooter';

// Banners
import CookieBanner from './Components/CookieBanner';

// Pages
import Home from './Pages/Home';
import SearchResults from './Pages/SearchResults';
import FAQs from './Pages/FAQs';
import About from './Pages/About';
import Feedback from './Pages/Feedback';
import Licensing from './Pages/Licensing';
import Cookies from './Pages/Cookies';
import Favourites from './Pages/Favourites';
import MixImport from './Pages/MixImport';
import NotFound from './Pages/NotFound';

function Router() {
  return (
    <BrowserRouter>
      <RatingModal />
      <CookieBanner />
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/search" component={SearchResults} />
        <Route exact path="/faqs" component={FAQs} />
        <Route exact path="/about" component={About} />
        <Route exact path="/feedback" component={Feedback} />
        <Route exact path="/licensing" component={Licensing} />
        <Route exact path="/cookies" component={Cookies} />
        <Route exact path="/favourites" component={Favourites} />
        <Route exact path="/favourites/:shareurl" component={Favourites} />
        <Route exact path="/miximport" component={MixImport} />
        <Route path={null} component={NotFound} />
      </Switch>
      <PageFooter />
    </BrowserRouter>
  );
}

export default Router;