import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { AppContext } from '../../Context/AppContext';
import getQuerystring from '../../utilities/getQuerystring';
import modifyExistingQuerystring from '../../utilities/modifyExistingQuerystring';
import { RESULT_SIZE_CONFIG } from '../../constants';

function ShowMoreResults({ history }) {
  const { state: { results, resultsLength, resultsLoading }, echoTrack } = useContext(AppContext);
  const pageContext = history.location.pathname.substring(1);
  const resultSizeLimit = RESULT_SIZE_CONFIG.sizeLimit;

  // Get search term from query string
  const parsedQuerystring = getQuerystring();

  const sizeLimitReached = parsedQuerystring.resultSize >= resultSizeLimit;

  const loadMore = () => {
    echoTrack(pageContext, { action: 'load_more' }, 'click');

    history.push(`${pageContext}?${modifyExistingQuerystring('resultSize', parsedQuerystring.resultSize ? parseInt(parsedQuerystring.resultSize, 10) + RESULT_SIZE_CONFIG.incrementSize : (RESULT_SIZE_CONFIG.incrementSize + 10))}`);
  };

  return (
    <div className="flex flex-col items-center justify-center py-4">
      {
        (results.length !== resultsLength) ? (
          <button
            type="button"
            className={`focus:outline-none px-16 py-2 font-bold text-center text-white bg-gray-900 hover:opacity-75 focus:opacity-75${(resultsLoading || sizeLimitReached) ? ' opacity-75 cursor-not-allowed' : ''}`}
            onClick={loadMore}
            disabled={resultsLoading || sizeLimitReached}
          >
            {
              (!resultsLoading && sizeLimitReached)
                ? ('Result limit reached')
                : (!resultsLoading ? 'Load more' : 'Loading...')
            }
          </button>
        )
          : (
            <div className="px-16 py-2 text-center border border-gray-900 opacity-50">
              No more results
            </div>
          )
      }
      <div className="relative w-40 h-2 my-3 bg-white border border-teal-700">
        <div className="absolute top-0 left-0 h-full bg-teal-700" style={{ width: `${(results.length / resultSizeLimit) * 100}%` }} />
      </div>
      <p className="text-sm font-bold">
        {`${results.length} of `}
        {resultsLength > resultSizeLimit ? `top ${resultSizeLimit} results (found ${resultsLength.toLocaleString()} total)` : `${resultsLength.toLocaleString()} total results`}
      </p>
    </div>
  );
}

ShowMoreResults.propTypes = {
  loadMore: PropTypes.func,
  resultSizeLimit: PropTypes.number,
  history: PropTypes.shape({
    push: PropTypes.func,
    listen: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  })
};

export default withRouter(ShowMoreResults);
