const AudioContext = window.AudioContext || window.webkitAudioContext;
const audioCtx = new AudioContext();
const streamDestination = audioCtx.createMediaStreamDestination();

export default {
  mixerModeOn: false,
  mixerOpen: false,
  mixerPlaying: false,
  mixerPlaybackReset: false,
  mixerAssets: {},
  mixerEditAssetId: null,
  mixImporting: false,
  error: '',
  playingMixerAssets: 0,
  readyMixerAssets: 0,
  audioCtx,
  streamDestination,
};
