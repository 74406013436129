import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { AppContext } from '../../Context/AppContext';
import { MixerContext } from '../../Context/MixerContext';

import BaseModal from '../BaseModal';
import GelIcon from '../GelIcon';

import { THEME_COLOURS } from '../../constants';

// https://www.russellgood.com/how-to-convert-audiobuffer-to-audio-file/#render-as-wav
function bufferToWave(abuffer, len) {
  var numOfChan = abuffer.numberOfChannels,
    length = len * numOfChan * 2 + 44,
    buffer = new ArrayBuffer(length),
    view = new DataView(buffer),
    channels = [], i, sample,
    offset = 0,
    pos = 0;

  function setUint16(data) {
    view.setUint16(pos, data, true);
    pos += 2;
  }

  function setUint32(data) {
    view.setUint32(pos, data, true);
    pos += 4;
  }

  // write WAVE header
  setUint32(0x46464952);                         // "RIFF"
  setUint32(length - 8);                         // file length - 8
  setUint32(0x45564157);                         // "WAVE"

  setUint32(0x20746d66);                         // "fmt " chunk
  setUint32(16);                                 // length = 16
  setUint16(1);                                  // PCM (uncompressed)
  setUint16(numOfChan);
  setUint32(abuffer.sampleRate);
  setUint32(abuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
  setUint16(numOfChan * 2);                      // block-align
  setUint16(16);                                 // 16-bit (hardcoded in this demo)

  setUint32(0x61746164);                         // "data" - chunk
  setUint32(length - pos - 4);                   // chunk length

  // write interleaved data
  for (i = 0; i < abuffer.numberOfChannels; i++)
    channels.push(abuffer.getChannelData(i));

  while (pos < length) {
    for (i = 0; i < numOfChan; i++) {             // interleave channels
      sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
      sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0; // scale to 16-bit signed int
      view.setInt16(pos, sample, true);          // write 16-bit sample
      pos += 2;
    }
    offset++;                                     // next source sample
  }

  // create Blob
  return new Blob([buffer], { type: "audio/wav" });
}

// https://stackoverflow.com/a/6313008
function secondsToMinutesFormatter(seconds) {
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds - (hours * 3600)) / 60);
  let secs = seconds - (hours * 3600) - (minutes * 60);

  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  if (secs < 10) secs = "0" + secs;

  return hours + ':' + minutes + ':' + secs;
}

function RecordMixModal({ setShowRecordMixModal }) {
  const { echoTrack } = useContext(AppContext);
  const {
    state: { streamDestination, audioCtx, mixerPlaying, mixerAssets },
    setMixerPlaying,
    setMixerPlaybackReset
  } = useContext(MixerContext);
  const [isRecording, setIsRecording] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState();
  const [audioMetadata, setAudioMetadata] = useState();
  const [downloadObjectUrl, setDownloadObjectUrl] = useState();
  const [wavFileSizeInBytes, setWavFileSizeInBytes] = useState(0);
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  const [timerInterval, setTimerInterval] = useState();
  const chunks = useRef([]);

  useEffect(() => {
    const mediaRecorderInstance = new MediaRecorder(streamDestination.stream);

    mediaRecorderInstance.ondataavailable = (event) => {
      chunks.current.push(event.data);
    };

    mediaRecorderInstance.onstop = () => {
      var blob = new Blob(chunks.current);
      chunks.current = [];

      blob.arrayBuffer().then(arrayBuffer => {
        audioCtx.decodeAudioData(arrayBuffer, audioBuffer => {
          const wav = bufferToWave(audioBuffer, audioBuffer.length);
          setAudioMetadata(audioBuffer);
          setDownloadObjectUrl(URL.createObjectURL(wav));
          setWavFileSizeInBytes(wav.size);
        });
      });
    };

    setMediaRecorder(mediaRecorderInstance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Stop recording if the mixer playback stops (i.e. all sounds have stopped wihtout looping)
  useEffect(() => {
    if (mediaRecorder && mediaRecorder.state === 'recording' && !mixerPlaying) {
      setIsRecording(false);
      mediaRecorder.stop();
    }
  }, [mixerPlaying, mediaRecorder]);

  const handleRecordClick = () => {
    // Mixer uses an audio context which may need to be initialised after a user action
    if (audioCtx.state === 'suspended') {
      audioCtx.resume();
    }

    if (isRecording) {
      mediaRecorder.stop();
      setMixerPlaying(false);
      setMixerPlaybackReset();

      clearInterval(timerInterval);
      setSecondsElapsed(0);
    } else {
      mediaRecorder.start();
      setMixerPlaying(true);
      setTimerInterval(setInterval(() => { setSecondsElapsed(secondsElapsed => secondsElapsed + 1); }, 1000))
    }

    setDownloadObjectUrl(null);
    setIsRecording(!isRecording);
  };

  const handleDownloadClick = () => {
    echoTrack('mixer', { action: 'mixer_download_recording' }, 'click');
  };

  const numOfSounds = Object.keys(mixerAssets).length;
  const duration = audioMetadata ? Math.round(audioMetadata.duration) : 0;

  return (
    <>
      {infoModalOpen ? (
        <BaseModal labelId="mixrecordinfo_modal_title" descriptionId="mixrecordinfo_desc">
          <p id="mixrecordinfo_desc" className="sr-only">Information regarding how to record a mix using the mix recording tool.</p>
          <div className="overflow-y-auto text-left text-gray-900 md:h-full">
            <div className="px-6 py-8 text-left sm:px-8">
              <div className="flex items-center justify-between pb-3">
                <div className="flex flex-row items-center space-x-3">
                  <span className="text-2xl font-bold" labelId="mixrecordinfo_modal_title">Mix Recording</span>
                </div>
                <button
                  type="button"
                  className="p-2 rounded-md focus:outline-none focus:bg-gray-300 hover:bg-gray-300"
                  onClick={() => setInfoModalOpen(false)}
                  aria-label="Close"
                >
                  <GelIcon name="close" fill={THEME_COLOURS.PRIMARY} />
                </button>
              </div>
              <div>
                <p className="mb-3 text-gray-900">
                  If you have some audio effects added to the mixer, it is possible to record your mix and save it
                  as a .wav file.
                </p>
                <p className="mb-3 text-gray-900">
                  The first time you open the recording box, there will be two buttons - one to start recording,
                  and another disabled button to download the recorded mix.
                </p>
                <p className="mb-3 text-gray-900">
                  To start recording your mix, simply click the &apos;Record&apos; button. You will hear your mix 
                  playing back to you in real time as it is recorded. Recording will automatically stop once all
                  of your sounds have finished playing, unless you have any of them set to loop. In either case,
                  you can stop the recording process by clicking the &apos;Record&apos; button again.
                </p>
                <p className="mb-3 text-gray-900">
                  Once a recording has been made and the recorder has been stopped, the download button will be
                  enabled and you can download your file.
                </p>
              </div>
            </div>
          </div>
        </BaseModal>
      ) : (
        <BaseModal labelId="mixrecord_modal_title">
          <div className="px-6 py-8 text-left sm:px-8">
            <div className="flex items-center justify-between pb-3">
              <div className="flex flex-row items-center space-x-3">
                <GelIcon name="music-track" fill={THEME_COLOURS.PRIMARY} className="w-4 h-4" />
                <span className="text-2xl font-bold text-gray-900" id="mixrecord_modal_title">Record Mix</span>
              </div>
              <button
                type="button"
                className={`p-2 rounded-md focus:outline-none focus:bg-gray-300 hover:bg-gray-300 ${isRecording ? 'cursor-not-allowed opacity-50' : ''}`}
                onClick={() => setShowRecordMixModal(false)}
                aria-label="Close"
                disabled={isRecording}
              >
                <GelIcon name="close" fill={THEME_COLOURS.PRIMARY} />
              </button>
            </div>
            <div className="flex items-center justify-center px-6 mx-auto">
              <button
                type="button"
                className={`flex flex-col items-center w-48 p-2 focus:outline-none md:space-y-2 rounded-md hover:bg-gray-300 focus:bg-gray-300`}
                onClick={handleRecordClick}
              >
                <GelIcon className={`w-12 h-12 ${isRecording ? 'animate-spin-slow' : ''}`} name="record" fill={isRecording ? 'red' : THEME_COLOURS.PRIMARY} />
                <span className="font-semibold text-gray-900">{isRecording ? 'Stop recording' : 'Start recording'}</span>
              </button>
            </div>
            <div className="flex justify-center w-full mt-5 md:px-6">
              <button
                className={`w-full font-bold text-white bg-teal-700 focus:outline-none ${!downloadObjectUrl || isRecording ? 'cursor-not-allowed opacity-50' : 'hover:opacity-75 focus:opacity-75'}`}
                onClick={handleDownloadClick}
                disabled={isRecording}
              >
                <a
                  href={downloadObjectUrl}
                  download={`mix-${moment().format('DD-MM-YYYY')}--${numOfSounds}-sounds--${duration}-seconds.wav`}
                  className={`flex items-center justify-center w-full py-3`}
                >
                  {downloadObjectUrl ? `Download wav (${(wavFileSizeInBytes / 1000000).toFixed(2)} MB)` : secondsToMinutesFormatter(secondsElapsed)}
                </a>
              </button>
            </div>
            <div className="flex justify-center w-full mt-3 md:px-6">
              <button
                type="button"
                onClick={() => setInfoModalOpen(!infoModalOpen)}
                className="flex flex-row items-center justify-between text-sm font-bold text-blue-800 focus:outline-none hover:underline focus:underline"
              >
                <GelIcon name="help" className="w-4 h-5 mr-2" fill={THEME_COLOURS.TERTIARY} />
                <span>What&apos;s this?</span>
              </button>
            </div>
            
          </div>
        </BaseModal>
      )}
    </>
  );
}

RecordMixModal.propTypes = {
  setShowRecordMixModal: PropTypes.func
};

export default RecordMixModal;