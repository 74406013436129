import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { AppContext } from '../../Context/AppContext';
import { MixerContext } from '../../Context/MixerContext';
import { FavouritesContext } from '../../Context/FavouritesContext';

import BaseModal from '../BaseModal';
import GelIcon from '../GelIcon';
import { THEME_COLOURS, BATCH_DOWNLOAD_FUNCTION } from '../../constants';
import useCustomSnackbars from "../../hooks/useCustomSnackbars";

function BatchDownloadModal({ fileType, setDownloadType, assetType }) {
  const { echoTrack } = useContext(AppContext);
  const { state: { mixerAssets } } = useContext(MixerContext);
  const { state: { favouriteIds } } = useContext(FavouritesContext);
  const { openErrorSnackbar } = useCustomSnackbars();

  const [isDownloading, setIsDownloading] = useState(false);
  const [socketUrl, setSocketUrl] = useState(null);

  const {
    sendJsonMessage,
    lastMessage,
    readyState
  } = useWebSocket(socketUrl);

  const downloadResponse = {
    statusCode: 'progress',
    downloadUrl: null,
    percentComplete: 0,
    ...(lastMessage && JSON.parse(lastMessage.data).percentComplete) && { ...JSON.parse(lastMessage.data) }
  };

  const handleError = (error) => {
    // Close modal and clear state
    setDownloadType(null);
    setIsDownloading(false);

    // Show error message
    if (error && error.message && error.message.toLowerCase().includes('timeout')) {
      openErrorSnackbar('Request has timed out.', 4000);
    } else {
      openErrorSnackbar('An error occurred when trying to download your sound clips. Please try again.', 4000);
    }
  };

  const handleAgreeClick = () => {
    echoTrack('batch_download', { action: 'batch_download', data: { location: assetType, fileType } }, 'click');

    setSocketUrl(BATCH_DOWNLOAD_FUNCTION);

    setIsDownloading(true);
  };

  // Ensure the WebSocket connection is 'open' before trying to send data
  useEffect(() => {
    if (ReadyState[readyState] === 'OPEN') {
      const assetIds =
        assetType === 'favourites'
          ? favouriteIds
          : _.uniq(Object.keys(mixerAssets).map(mixerId => mixerAssets[mixerId].id));

      sendJsonMessage({ 'action': 'zip', files: assetIds.map(id => `${id}.${fileType}`), fileType });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyState]);

  if (downloadResponse.statusCode === 'error') {
    handleError();
  }

  const downloadPercent = Math.round(downloadResponse.percentComplete);

  return (
    <BaseModal labelId="download_modal_title" descriptionId="download_modal_info">
      <p className="sr-only" id="download_modal_info">A tool for downloading a batch of sound effects. You must agree to the Remarc licence to continue.</p>
      <div className="text-left">
        <div className="px-6 pt-6 sm:px-8 sm:pt-8">
          <div className="flex items-center justify-between pb-3">
            <div className="flex flex-row items-center space-x-3">
              <GelIcon name="download" fill={THEME_COLOURS.PRIMARY} className="w-4 h-4" />
              <span id="download_modal_title" className="text-2xl font-bold text-gray-900">Download {assetType === 'favourites' ? 'Favourites' : 'Mixer Assets'}</span>
            </div>
            <button type="button" className="p-2 rounded-md focus:outline-none focus:bg-gray-300 hover:bg-gray-300" onClick={() => setDownloadType(null)} aria-label="Close">
              <GelIcon name="close" fill={THEME_COLOURS.PRIMARY} />
            </button>
          </div>
          {
            !isDownloading ? (
              <>
                <p className="mb-5">
                  By continuing, you agree to comply with the terms of the&nbsp;
                  <a href="/licensing" target="_blank" rel="noopener noreferrer" className="font-bold text-blue-800 underline">
                    RemArc Licence
                  </a>
                      &nbsp;for this and any future downloads.
                </p>
                <p className="mb-6">Commercial use of this content is not allowed under the RemArc license.</p>
                <p className="mb-6">
                  For commercial use, buy the sound effect from <strong>Pro Sound Effects</strong> which can be found
                  in the <strong>Show details</strong> section for each sound effect.
                </p>
                {
                  fileType === 'wav' && (
                    <div className="px-4 py-3 mb-6 text-sm bg-gray-200 rounded-md md:mb-0">
                      <strong className="text-gray-900">WAV format will take a bit longer to download</strong>
                    </div>
                  )
                }
              </>
            )
              : (
                <div>
                  <p className="mb-5">Depending on how many favourites you have, it can take a minute or two to create a bundle for you to download.</p>
                  <div className="px-4 py-5 mb-6 space-y-2 bg-gray-100 rounded-md md:mb-0">
                    <div className="text-sm font-bold text-gray-900">{`${!downloadResponse.downloadUrl ? 'Creating bundle...' : 'Your bundle is ready to download!'}`}</div>
                    <div className="relative w-full h-5 bg-gray-400 rounded-full">
                      <div
                        className={`absolute top-0 left-0 h-full transition-all duration-200 ease-in-out bg-teal-700 ${downloadPercent <= 98 ? 'rounded-tl-full rounded-bl-full' : 'rounded-full'}`}
                        style={{ width: `${downloadPercent}%` }}
                      />
                    </div>
                  </div>
                </div>
              )
          }
        </div>
        <div className="flex justify-end w-full mt-4 sm:pr-8 sm:pb-8">
          {
            !isDownloading ? (
              <button
                type="button"
                className="w-full px-6 py-3 font-bold text-white bg-teal-700 focus:outline-none sm:w-auto hover:opacity-75 focus:opacity-75"
                onClick={handleAgreeClick}
              >
                Agree
              </button>
            )
              : (
                <a
                  href={downloadResponse.downloadUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  disabled={!downloadResponse.downloadUrl}
                  className={`w-full flex flex-row items-center justify-center px-6 py-3 font-bold text-center text-white bg-teal-700 sm:w-auto${!downloadResponse.downloadUrl ? ' opacity-50 cursor-not-allowed' : ' hover:opacity-75 focus:opacity-75'}`}
                >
                  {!downloadResponse.downloadUrl && <GelIcon name="loading" fill={THEME_COLOURS.DARK_GREY} className="w-4 h-4 mr-2 animate-spin" />}
                  {!downloadResponse.downloadUrl ? 'Please wait' : 'Download'}
                </a>
              )
          }
        </div>
      </div>
    </BaseModal>
  );
}

BatchDownloadModal.propTypes = {
  fileType: PropTypes.string,
  setDownloadType: PropTypes.func,
  assetType: PropTypes.string.isRequired,
};

export default BatchDownloadModal;