import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { AppContext } from '../../Context/AppContext';
import { SOURCES } from '../../constants';
import moment from 'moment';

const bytesToKBOrMB = (bytes) => {
  let result = bytes / 1000;
  if (result > 1000) {
    result = (result / 1000).toFixed(2) + 'MB';
  } else {
    result = result.toFixed(2) + 'KB';
  }
  return result;
};

// ensures that search drops any ampersands and other characters from known text searches
const stripSpecialCharacters = (displayText) => {
  displayText = displayText.replace('&', 'and');
  displayText = displayText.replace(/[^\w\s]+?/gs, ''); // Match against any character that is not ([^...]) a word (\w) or whitespace (\s) and remove
  return displayText;
};

const FileMetadata = ({ asset, placement, mixerVersion }) => {
  const { state: { mixerOpen }, setMixerOpen } = useContext(AppContext);

  return (
    <div className={`${placement} w-full md:flex md:flex-col md:pl-2`}>
      {asset.technicalMetadata && asset.technicalMetadata.sample_rate && asset.technicalMetadata.bits_per_sample && (
        <div className="flex-row text-sm">
          <strong className="mr-1">File info: </strong>
          <span>
            MP3, 128kbps &bull; WAV, {asset.technicalMetadata.sample_rate / 1000}KHz,&nbsp;
            {asset.technicalMetadata.bits_per_sample}bit
          </span>
        </div>
      )}
      {asset.fileSizes && (
        <div className="flex-row mt-1 text-sm">
          <strong className="mr-1">File size: </strong>
          <span>
            MP3, {bytesToKBOrMB(asset.fileSizes.mp3FileSize)} &bull; WAV, {bytesToKBOrMB(asset.fileSizes.wavFileSize)}
          </span>
        </div>
      )}
      {asset.technicalMetadata && asset.technicalMetadata.channels && (
        <div className="flex-row mt-1 text-sm">
          <strong className="mr-1">Channels: </strong>
          <span>{asset.technicalMetadata.channels}</span>
        </div>
      )}
      {
        (asset.additionalMetadata && asset.additionalMetadata.recordist) && (
          <div className="flex-row mt-1 text-sm">
            <strong className="mr-1">Recordist(s): </strong>
            <Link
              to={`/search?q=&recordist=${stripSpecialCharacters(asset.additionalMetadata.recordist)}`}
              className={`text-teal-${!mixerOpen ? '700' : '500'} font-bold hover:underline focus:underline outline-none`}
              onClick={mixerOpen ? () => setMixerOpen(false) : null}
            >
              {asset.additionalMetadata.recordist}
            </Link>
          </div>
        )
      }
      {
        (asset.location && asset.location.continent) && (
          <div className="flex-row mt-1 text-sm">
            <strong className="mr-1">Location: </strong>
            <Link
              to={`/search?q=&continents=${asset.location.continent}`}
              className={`text-teal-${!mixerOpen ? '700' : '500'} font-bold hover:underline focus:underline outline-none`}
              onClick={mixerOpen ? () => setMixerOpen(false) : null}
            >
              {asset.location.continent}
            </Link>
            {asset.location.country && <span>{`, ${asset.location.country}`}</span>}
            {asset.location.city && <span>{`, ${asset.location.city}`}</span>}
            {asset.location.place && <span>{`, ${asset.location.place}`}</span>}
          </div>
        )
      }
      {
        (asset.additionalMetadata && asset.additionalMetadata.habitat) && (
          <div className="flex-row mt-1 text-sm">
            <strong className="mr-1">Habitat: </strong>
            <Link
              to={`/search?q=&habitat=${asset.additionalMetadata.habitat}`}
              className={`text-teal-${!mixerOpen ? '700' : '500'} font-bold hover:underline focus:underline outline-none`}
              onClick={mixerOpen ? () => setMixerOpen(false) : null}
            >
              {asset.additionalMetadata.habitat}
            </Link>
          </div>
        )
      }
      {
        asset.source && (
          <div className="flex-row mt-1 text-sm">
            <strong className="mr-1">Source: </strong>
            <Link
              to={`/search?q=&source=${asset.source}`}
              className={`${!mixerVersion ? 'text-teal-700' : 'text-white'} font-bold hover:underline focus:underline outline-none`}
              onClick={mixerOpen ? () => setMixerOpen(false) : null}
            >
              {SOURCES[asset.source].text}
            </Link>
          </div>
        )
      }
      {
        asset.recordedDate && asset.recordedDate !== 'unknown' && (
          <div className="flex-row mt-1 text-sm">
            <strong className="mr-1">Date recorded: </strong>
            <span>
              {moment(asset.recordedDate, moment.ISO_8601).format("Do MMMM YYYY").toString()}
            </span>
          </div>
        )
      }
    </div>
  );
};

FileMetadata.propTypes = {
  asset: PropTypes.object,
  placement: PropTypes.string,
  mixerVersion: PropTypes.bool,
};

export default FileMetadata;
