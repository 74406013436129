import React from 'react';
import PropTypes from 'prop-types';

import { THEME_COLOURS } from '../../constants';
import GelIcon from '../GelIcon';

const ActiveFilterButton = ({ closeAction, filterValue, iconName, viewBox }) => (
  <button
    key={filterValue}
    type="button"
    className="flex flex-row items-center justify-between w-full px-8 py-3 focus:outline-none"
    onClick={closeAction}
    title={`Remove '${filterValue}' filter`}
  >
    <div className="flex flex-row items-center">
      <GelIcon name={iconName} fill={THEME_COLOURS.SECONDARY} className="w-5 h-5 mr-3" viewBox={viewBox} />
      {filterValue}
    </div>
    <GelIcon name="close" fill={THEME_COLOURS.PRIMARY} className="w-5 h-5" />
  </button>
);

ActiveFilterButton.propTypes = {
  closeAction: PropTypes.func.isRequired,
  filterValue: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  viewBox: PropTypes.string,
};

export default ActiveFilterButton;
