// https://stackoverflow.com/a/58064481
export default () => {
  const isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1));
  const isIPadBeforeIOS13 = (/iPad/.test(navigator.platform));
  const isIPadIOS13AndAbove = (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  let version = null;

  // Can't get version info from iPads with iOS >= 13
  if (isIOS && !isIPadIOS13AndAbove) {
    // https://stackoverflow.com/a/50035797
    version = +(navigator.userAgent).match(/OS (\d)?\d_\d(_\d)?/i)[0].split('_')[0].replace("OS ","");
  }

  return {
    isIOS,
    isIPadBeforeIOS13,
    isIPadIOS13AndAbove,
    version,
  }
};