import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { CATEGORY_LIST, CATEGORY_LIST_MOST_POPULAR, INITIAL_CATEGORIES_MD, INITIAL_CATEGORIES_SM } from '../../constants';
import { AppContext } from '../../Context/AppContext';
import GelIcon from '../../Components/GelIcon';
import HomepagePromo from '../../Components/HomepagePromo';
import { MixerContext } from '../../Context/MixerContext';
import getQueryString from '../../utilities/getQuerystring';

const CategoryCard = ({ category, value, focusRef }) => {
  return (
    <Link
      to={`/search?cat=${category.name}`}
      className="flex items-center p-3 bg-white border-2 border-transparent border-solid rounded-md shadow-sm group hover:border-teal-700 focus:border-teal-700 focus:outline-none"
      ref={focusRef}
    >
      <div className={`flex items-center justify-center w-8 h-8 mr-2 rounded-full group-hover:bg-teal-700 group-focus:bg-teal-700 bg-teal-100 text-teal-700 group-focus:text-white group-hover:text-white`}>
        <GelIcon name={category.icon} viewBox={category.viewBox} fill={null} className="w-4 h-4 fill-current" />
      </div>
      <p className="text-black"><span className="font-semibold no-underline group-focus:underline group-hover:underline">{category.name.replace('_', ' ')}</span> {value ? `(${value})` : ''}</p>
    </Link>
  );
};

CategoryCard.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    viewBox: PropTypes.string,
  }),
  value: PropTypes.number.isRequired,
  focusRef: PropTypes.shape({}),
};

function Home({ history }) {
  const { clearSearchResults, state: { categoryAggregations }, echoTrack } = useContext(AppContext);
  const { state: { mixerModeOn }, toggleMixer } = useContext(MixerContext);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const firstCategoryRef = useRef();

  // Clear search results from state on component mount
  useEffect(() => {
    const queryParams = getQueryString();
    if (queryParams && (queryParams.q || queryParams.cat)) {
      //redirect to the search page, assuming no prior search state
      history.push(`/search?
      ${queryParams.q ? `q=${queryParams.q}` : ''}
      ${queryParams.q && queryParams.cat ? '&' : ''}
      ${queryParams.cat ? `cat=${queryParams.cat}` : ''}`);
    }

    echoTrack('homepage');

    clearSearchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    if (showAllCategories) {
      firstCategoryRef.current.focus();
    }
  }, [showAllCategories]);

  const categoriesToShow = showAllCategories
    ? CATEGORY_LIST
    : CATEGORY_LIST_MOST_POPULAR.map(category => CATEGORY_LIST[CATEGORY_LIST.findIndex(el => el.name === category)]);

  return (
    <>
      <Helmet title="BBC Sound Effects" />
      <div className={`${mixerModeOn ? 'bg-gray-700' : 'bg-gray-100'} transition-all duration-200 ease-in-out`}>
        <div className="max-w-screen-xl min-h-full px-3 mx-auto">
          <div className="flex w-full py-5 md:py-10">
            <div className="flex flex-col w-full md:flex-row md:w-8/12 lg:w-9/12 md:mr-5">
              <div className="w-full mb-5 mr-0 md:mr-5 md:mb-0">
                <Link to="/search" className="relative flex flex-col justify-start h-auto p-4 overflow-hidden text-xl font-bold leading-tight text-white duration-200 transform bg-blue-800 rounded-lg lg:text-2xl md:h-48 lg:h-56 hover:underline focus:underline hover:-translate-y-1 focus:-translate-y-1 bg-gradient-to-br from-blue-800 to-blue-500">
                  <h2>Browse all BBC<br />Sound Effects</h2>
                  <div className="absolute top-0 right-0 w-16 h-full mr-2 md:mr-0 md:w-32">
                    <svg viewBox="0 0 128 286" className="">
                      <rect y="95.2358" width="34.9489" height="177.366" fill="white" fillOpacity="0.15" />
                      <rect x="92.6147" y="61.1606" width="34.9489" height="150" fill="white" fillOpacity="0.15" />
                      <rect x="46.3073" width="34.9489" height="285.708" fill="white" fillOpacity="0.15" />
                    </svg>
                  </div>
                </Link>
              </div>
              <div className="w-full">
                <Link onClick={() => toggleMixer(true)} to="/search" className="relative flex flex-col justify-start h-auto p-4 overflow-hidden text-xl font-bold leading-tight text-white duration-200 transform bg-teal-700 rounded-lg lg:text-2xl md:h-48 lg:h-56 bg-gradient-to-br from-teal-700 to-teal-400 hover:underline focus:underline hover:-translate-y-1 focus:-translate-y-1">
                  <h2>Mixer Mode:<br />Create your own Soundscape</h2>
                  <div className="absolute top-0 right-0 w-48 h-full overflow-hidden md:w-96">
                    <svg viewBox="0 0 388 334">
                      <rect x="89.1865" y="90.9565" width="33.4412" height="100.324" fill="white" fillOpacity="0.1" />
                      <rect x="177.644" y="74.7752" width="33.4412" height="156.419" fill="white" fillOpacity="0.1" />
                      <rect x="133.415" y="51.0426" width="33.4412" height="221.144" fill="white" fillOpacity="0.1" />
                      <rect x="221.873" y="90.9565" width="33.4412" height="169.364" fill="white" fillOpacity="0.1" />
                      <rect x="310.33" y="58.5939" width="33.4412" height="172.6" fill="white" fillOpacity="0.1" />
                      <rect x="354.559" y="115.768" width="33.4412" height="91.6937" fill="white" fillOpacity="0.1" />
                      <rect x="266.102" y="0.341431" width="33.4412" height="271.845" fill="white" fillOpacity="0.1" />
                      <rect x="0.0668945" y="152.117" width="33.4412" height="100.324" fill="white" fillOpacity="0.1" />
                      <rect x="88.5242" y="135.936" width="33.4412" height="156.419" fill="white" fillOpacity="0.2" />
                      <rect x="44.2957" y="112.203" width="33.4412" height="221.144" fill="white" fillOpacity="0.1" />
                      <rect x="132.753" y="152.117" width="33.4412" height="169.364" fill="white" fillOpacity="0.2" />
                      <rect x="221.211" y="119.755" width="33.4412" height="172.6" fill="white" fillOpacity="0.2" />
                      <rect x="265.439" y="176.928" width="33.4412" height="91.6937" fill="white" fillOpacity="0.2" />
                      <rect x="176.982" y="61.5021" width="33.4412" height="271.845" fill="white" fillOpacity="0.2" />
                    </svg>
                  </div>
                </Link>
              </div>
            </div>
            <div className="hidden w-full md:flex md:w-4/12 lg:w-3/12">
              <Link to="/licensing" className="relative flex flex-col justify-start p-4 overflow-hidden text-xl font-bold leading-tight text-white duration-200 transform bg-gray-900 rounded-lg md:h-48 lg:h-56 lg:text-2xl hover:underline focus:underline hover:-translate-y-1 focus:-translate-y-1 bg-gradient-to-br from-gray-900 to-gray-800">
                <h2>BBC Sound Effects Licences &amp; Usage</h2>
                <div className="absolute bottom-0 right-0 w-32 h-full">
                  <svg viewBox="0 0 127 280">
                    <rect y="41.9387" width="34.9489" height="104.847" fill="white" fillOpacity="0.15" />
                    <rect x="92.6147" y="24.4642" width="34.0752" height="164.26" fill="white" fillOpacity="0.15" />
                    <rect x="46.3073" width="34.0752" height="231.537" fill="white" fillOpacity="0.15" />
                  </svg>
                </div>
              </Link>
            </div>
          </div>
          <div>
            <HomepagePromo />
          </div>
          <div className="block w-full pt-0 pb-4 border-gray-400 md:py-4 md:border-t">
            <h2 className="text-xl font-bold md:text-2xl">Browse Categories</h2>
          </div>
          {/* Categories screen md and up */}
          {/* Falls back to Flexbox if CSS-Grid is not supported */}
          <div className="hidden grid-cols-3 gap-4 md:grid lg:grid-cols-4 md:flex md:flex-wrap">
            {
              categoriesToShow.slice(0, !showAllCategories ? INITIAL_CATEGORIES_MD : CATEGORY_LIST.length)
                .map((category, index) => (
                  <CategoryCard
                    key={category.name.toLowerCase()}
                    category={category}
                    value={categoryAggregations[category.name] ? categoryAggregations[category.name].doc_count : 0}
                    focusRef={index === 0 ? firstCategoryRef : null}
                  />
                ))
            }
            {showAllCategories && (
              <Link
                to="/search"
                className="flex items-center justify-between px-6 py-3 bg-teal-700 rounded-md shadow-sm hover:opacity-75 focus:opacity-75 focus:outline-none"
              >
                <span className="font-semibold text-white">Browse all categories</span>
                <GelIcon name="chevron-right" className="w-4 h-4" fill="white" />
              </Link>
            )}
          </div>
          {!showAllCategories && (
            <div className="items-center justify-center hidden mt-4 md:flex">
              <button
                onClick={() => setShowAllCategories(true)}
                className="px-16 py-3 font-semibold text-white bg-teal-700 rounded-md shadow-sm hover:opacity-75 focus:opacity-75"
              >
                Show all categories
              </button>
            </div>
          )}
          {/* Categories screen sm and down */}
          <div className="grid grid-cols-1 gap-4 md:hidden sm:grid-cols-2 ">
            {
              categoriesToShow.slice(0, !showAllCategories ? INITIAL_CATEGORIES_SM : CATEGORY_LIST.length)
                .map(category => (
                  <CategoryCard
                    key={category.name.toLowerCase()}
                    category={category}
                    value={categoryAggregations[category.name] ? categoryAggregations[category.name].doc_count : 0}
                  />
                ))
            }
            {showAllCategories && (
              <Link
                to="/search"
                className="flex items-center justify-between px-6 py-3 bg-teal-700 rounded-md shadow-sm hover:opacity-75 focus:opacity-75 focus:outline-none"
              >
                <span className="font-semibold text-white">Browse all categories</span>
                <GelIcon name="chevron-right" className="w-4 h-4" fill="white" />
              </Link>
            )}
          </div>
          {!showAllCategories && (
            <div className="flex items-center justify-center mt-4 md:hidden">
              <button
                onClick={() => setShowAllCategories(true)}
                className="px-16 py-3 font-semibold text-white bg-teal-700 rounded-md shadow-sm hover:opacity-75 focus:opacity-75"
              >
                Show all categories
              </button>
            </div>
          )}
          <div className="flex flex-col py-10 md:flex-row md:space-y-0">
            <Link to="/about" className="relative flex justify-between w-full h-auto p-4 mt-5 mb-5 text-lg font-bold leading-tight text-white duration-200 transform bg-gray-900 rounded-lg md:text-xl lg:text-2xl md:flex-col md:justify-between md:h-32 hover:underline focus:underline hover:-translate-y-1 focus:-translate-y-1 md:mt-0 md:mb-0 md:mr-5">
              <h2>About BBC Sound Effects</h2>
              <div className="flex justify-end md:w-full">
                <GelIcon name="chevron-right" fill="white" className="w-6 h-6 md:w-8 md:h-8" />
              </div>
            </Link>
            <Link to="/faqs" className="relative flex justify-between w-full h-auto p-4 mb-5 text-lg font-bold leading-tight text-white duration-200 transform bg-gray-900 rounded-lg md:text-xl lg:text-2xl md:flex-col md:justify-between md:h-32 hover:underline focus:underline hover:-translate-y-1 focus:-translate-y-1 md:my-0 md:mr-5">
              <h2>Frequently Asked Questions</h2>
              <div className="flex justify-end md:w-full">
                <GelIcon name="chevron-right" fill="white" className="w-6 h-6 md:w-8 md:h-8" />
              </div>
            </Link>
            <Link to="/favourites" className="relative flex justify-between w-full h-auto p-4 text-lg font-bold leading-tight text-white duration-200 transform bg-blue-800 rounded-lg md:text-xl lg:text-2xl md:flex-col md:justify-between md:h-32 hover:underline focus:underline hover:-translate-y-1 focus:-translate-y-1">
              <h2>My Favourites List</h2>
              <div className="flex justify-end md:w-full">
                <GelIcon name="chevron-right" fill="white" className="w-6 h-6 md:w-8 md:h-8" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

Home.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }),
};

export default withRouter(Home);
