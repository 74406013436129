import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { AppContext } from '../../Context/AppContext';

import getQuerystring from '../../utilities/getQuerystring';
import modifyExistingQuerystring from '../../utilities/modifyExistingQuerystring';
import FilterPill from './FilterPill';
import { CATEGORY_LIST, DURATION_LIST, SOURCES } from '../../constants';
import { parseCategories, parseDurations, parseContinents, parseSources } from '../../utilities/queryParamParsers';

const AppliedFilters = ({ history }) => {
  const { echoTrack } = useContext(AppContext);

  // Store a reference to the page the user is on
  const pageContext = history.location.pathname.substring(1);

  // Get search term from query string
  const parsedQuerystring = getQuerystring();

  // Use valid search term or default to empty
  const [categories, setCategories] = useState(parseCategories(parsedQuerystring.cat));
  const [durations, setDurations] = useState(parseDurations(parsedQuerystring.durations));
  const [sources, setSources] = useState(parseSources(parsedQuerystring.source));
  const [continents, setContinents] = useState(parseContinents(parsedQuerystring.continents));
  const [recordists, setRecordists] = useState(parsedQuerystring.recordist ? parsedQuerystring.recordist.split(',') : []);
  const [habitats, setHabitats] = useState(parsedQuerystring.habitat ? parsedQuerystring.habitat.split(',') : []);

  // Update the filter properties when the URL params change
  useEffect(() => {
    // Validate URL params to prevent page failures due to unexpected values
    setCategories(parseCategories(parsedQuerystring.cat));
    setDurations(parseDurations(parsedQuerystring.durations));
    setSources(parseSources(parsedQuerystring.source));
    setContinents(parseContinents(parsedQuerystring.continents));
    setRecordists(parsedQuerystring.recordist ? parsedQuerystring.recordist.split(',') : []);
    setHabitats(parsedQuerystring.habitat ? parsedQuerystring.habitat.split(',') : []);
  }, [
    parsedQuerystring.cat,
    parsedQuerystring.durations,
    parsedQuerystring.source,
    parsedQuerystring.continents,
    parsedQuerystring.recordist,
    parsedQuerystring.habitat,
  ]);

  const performSearch = (urlParam, filters) => {
    echoTrack(pageContext, { action: 'desktop_filter_removed' }, 'click');

    history.push(`/${pageContext}?${modifyExistingQuerystring(urlParam, filters.join(','))}`);
  };

  return (
    <>
      {categories.concat(durations, continents, recordists, sources, habitats).length > 0 &&
        <div className="flex flex-wrap items-center max-w-screen-xl px-3 pt-3 mx-auto">
          {categories.map(activeCategory =>
            <FilterPill
              iconName={CATEGORY_LIST[CATEGORY_LIST.findIndex(el => el.name === activeCategory)].icon}
              viewBox={CATEGORY_LIST[CATEGORY_LIST.findIndex(el => el.name === activeCategory)].viewBox}
              filterValue={activeCategory.replace('_', ' ')}
              key={activeCategory}
              closeAction={() => performSearch('cat', categories.filter(category => category !== activeCategory))}
            />)
          }
          {durations.map(activeDuration =>
            <FilterPill
              iconName="clock"
              filterValue={DURATION_LIST[DURATION_LIST.map(el => el.query).indexOf(activeDuration)].text}
              key={activeDuration}
              closeAction={() => performSearch('durations', durations.filter(duration => duration !== activeDuration))}
            />)
          }
          {continents.map(activeContinent =>
            <FilterPill
              iconName="map"
              filterValue={activeContinent}
              key={activeContinent}
              closeAction={() => performSearch('continents', continents.filter(continent => continent !== activeContinent))}
            />)
          }
          {recordists.map(activeRecordist =>
            <FilterPill
              iconName="microphone"
              filterValue={activeRecordist}
              key={activeRecordist}
              closeAction={() => performSearch('recordist', recordists.filter(recordist => recordist !== activeRecordist))}
            />)
          }
          {sources.map(activeSource =>
            <FilterPill
              iconName="collection"
              filterValue={SOURCES[activeSource].text}
              key={activeSource}
              closeAction={() => performSearch('source', sources.filter(source => source !== String(activeSource)))}
            />)
          }
          {habitats.map(activeHabitat =>
            <FilterPill
              iconName="in-season"
              filterValue={activeHabitat}
              key={activeHabitat}
              closeAction={() => performSearch('habitat', habitats.filter(habitat => habitat !== activeHabitat))}
            />)
          }
        </div>
      }
    </>
  );
};

AppliedFilters.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    listen: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  })
};

export default withRouter(AppliedFilters);
