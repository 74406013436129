import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from '../../Context/AppContext';

import ActiveFilterButton from './ActiveFilterButton';
import getQuerystring from '../../utilities/getQuerystring';
import modifyExistingQuerystring from '../../utilities/modifyExistingQuerystring';
import { withRouter } from 'react-router';
import { DURATION_LIST, SOURCES, CATEGORY_LIST } from '../../constants';
import { parseCategories, parseDurations, parseContinents, parseSources } from '../../utilities/queryParamParsers';

const AppliedFilters = ({ history }) => {
  const { echoTrack } = useContext(AppContext);
  // Store a reference to the page the user is on
  const pageContext = history.location.pathname.substring(1);

  // Get search term from query string
  const parsedQuerystring = getQuerystring();

  // Use search term or default to empty
  const [categories, setCategories] = useState(parseCategories(parsedQuerystring.cat));
  const [durations, setDurations] = useState(parseDurations(parsedQuerystring.durations));
  const [sources, setSources] = useState(parseSources(parsedQuerystring.source));
  const [continents, setContinents] = useState(parseContinents(parsedQuerystring.continents));
  const [recordists, setRecordists] = useState(parsedQuerystring.recordist ? parsedQuerystring.recordist.split(',') : []);
  const [habitats, setHabitats] = useState(parsedQuerystring.habitat ? parsedQuerystring.habitat.split(',') : []);

  // Update the filter properties when the URL params change
  useEffect(() => {
    setCategories(parseCategories(parsedQuerystring.cat));
    setDurations(parseDurations(parsedQuerystring.durations));
    setSources(parseSources(parsedQuerystring.source));
    setContinents(parseContinents(parsedQuerystring.continents));
    setRecordists(parsedQuerystring.recordist ? parsedQuerystring.recordist.split(',') : []);
    setHabitats(parsedQuerystring.habitat ? parsedQuerystring.habitat.split(',') : []);
  }, [
    parsedQuerystring.cat,
    parsedQuerystring.durations,
    parsedQuerystring.continents,
    parsedQuerystring.recordist,
    parsedQuerystring.source,
    parsedQuerystring.habitat,
  ]);

  const performSearch = (urlParam, filters) => {
    echoTrack(pageContext, { action: 'mobile_filter_removed' }, 'click');

    history.push(`/${pageContext}?${modifyExistingQuerystring(urlParam, filters.join(','))}`);
  };

  return (
    <div>
      {categories.map(activeCategory => (
        <ActiveFilterButton
          key={activeCategory}
          iconName={CATEGORY_LIST[CATEGORY_LIST.findIndex(el => el.name === activeCategory)].icon}
          viewBox={CATEGORY_LIST[CATEGORY_LIST.findIndex(el => el.name === activeCategory)].viewBox}
          filterValue={activeCategory.replace('_', ' ')}
          closeAction={() => performSearch('cat', categories.filter(category => category !== activeCategory))}
        />
      ))}
      {durations.map(activeDuration => (
        <ActiveFilterButton
          key={activeDuration}
          iconName="clock"
          filterValue={DURATION_LIST[DURATION_LIST.map(el => el.query).indexOf(activeDuration)].text}
          closeAction={() => performSearch('durations', durations.filter(duration => duration !== activeDuration))}
        />
      ))}
      {continents.map(activeContinent => (
        <ActiveFilterButton
          key={activeContinent}
          iconName="map"
          filterValue={activeContinent}
          closeAction={() => performSearch('continents', continents.filter(continent => continent !== activeContinent))}
        />
      ))}
      {recordists.map(activeRecordist =>
        <ActiveFilterButton
          iconName="microphone"
          filterValue={activeRecordist}
          key={activeRecordist}
          closeAction={() => performSearch('recordist', recordists.filter(recordist => recordist !== activeRecordist))}
        />)
      }
      {sources.map(activeSource =>
        <ActiveFilterButton
          iconName="collection"
          filterValue={SOURCES[activeSource].text}
          key={activeSource}
          closeAction={() => performSearch('source', sources.filter(source => source !== String(activeSource)))}
        />)
      }
      {habitats.map(activeHabitat =>
        <ActiveFilterButton
          iconName="in-season"
          filterValue={activeHabitat}
          key={activeHabitat}
          closeAction={() => performSearch('habitat', habitats.filter(habitat => habitat !== activeHabitat))}
        />)
      }
    </div>
  );
};

AppliedFilters.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    listen: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  })
};

export default withRouter(AppliedFilters);
