import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import './styles/index.css';

if (!global.Intl) {
  // legacy (IE11) polyfill support for react-time-picker https://github.com/andyearnshaw/Intl.js/
  Promise.all([
    import('intl'),
    import('intl/locale-data/jsonp/en.js'),
  ]).then(() => {
    ReactDOM.render(<App />, document.getElementById("root"));
  });
} else {
  ReactDOM.render(<App />, document.getElementById("root"));
}