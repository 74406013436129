import React, { useState } from 'react';
import GelIcon from '../GelIcon';
import BaseModal from '../BaseModal';
import SMPPlayer from '../SMPPlayer';

import { THEME_COLOURS } from '../../constants';

const BG_IMAGE = "https://ichef.bbci.co.uk/images/ic/1920xn/p094q3qm.jpg";
const VIDEO_VPID = "p094vvgg";
const VIDEO_THUMBNAIL = "https://ichef.bbci.co.uk/images/ic/1280xn/p094vtzv.jpg";

function HomepagePromo() {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <>
      <button
        type="button"
        className="relative w-full p-4 mb-5 duration-200 transform bg-gray-900 rounded-lg group md:py-8 md:px-5 md:mb-10 hover:-translate-y-1 focus:-translate-y-1"
        onClick={() => setShowVideo(true)}
      >
        <div className="absolute top-0 bottom-0 left-0 right-0 bg-no-repeat rounded-lg opacity-25" style={{ background: `url(${BG_IMAGE})` }} />
        <div className="relative">
          <div className="w-64 text-xl font-bold leading-tight text-left text-white md:w-84 lg:text-2xl">
            Watch Radio 1&apos;s Sian Eleri make a SFX mix
          </div>

          <div className="flex-row justify-end hidden mt-4 md:flex">
            <div
              className="flex flex-row items-center justify-center px-3 py-2 border-2 border-white group-hover:bg-white group-focus:bg-white"
            >
              <span className="mr-2 text-sm font-bold text-white uppercase group-hover:text-black group-focus:text-black">Play video</span>
              <GelIcon name="chevron-right" className="w-4 h-4 fill-white group-hover:fill-black group-focus:fill-black" fill={null}></GelIcon>
            </div>
          </div>
        </div>
      </button>
      {
        showVideo && (
          <BaseModal width="3xl" labelId="promo_modal" descriptionId="promo_modal">
            <p className="sr-only" id="promo_modal">Promotional video for mixer mode</p>
            <div className="overflow-y-auto text-left text-gray-900 md:h-full">
              <div className="p-6">
                <div className="flex items-center justify-end pb-3">
                  <button
                    type="button"
                    className="p-2 rounded-md focus:outline-none focus:bg-gray-300 hover:bg-gray-300"
                    onClick={() => setShowVideo(false)}
                    aria-label="Close"
                  >
                    <GelIcon name="close" fill={THEME_COLOURS.PRIMARY} />
                  </button>
                </div>
                <div className="bg-black">
                  <SMPPlayer
                    vpid={VIDEO_VPID}
                    thumbnail={VIDEO_THUMBNAIL}
                  />
                </div>
              </div>
            </div>
          </BaseModal>
        )
      }
    </>
  );
}

export default HomepagePromo;