import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { AppContext } from '../../Context/AppContext';

function Licensing() {
  const { echoTrack } = useContext(AppContext);

  useEffect(() => {
    echoTrack('licensing');

    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet title="Licensing | BBC Sound Effects" />
      <div className="max-w-screen-xl min-h-screen px-3 mx-auto">
        <h2 className="py-5 text-3xl font-bold md:py-10">Licensing</h2>
        <div className="pb-10 space-y-5">
          <h2 className="text-2xl font-bold">Terms of Use for the BBC’s digital services</h2>
          <h3 className="text-2xl font-bold">The BBC’s Content Licence for RemArc</h3>
          <h4 className="text-xl font-bold">1. Hello</h4>
          <div>
            These are the BBC’s terms of use. They tell you:
            <ul className="py-2 ml-10 list-disc">
              <li>The rules for using this content</li>
              <li>What you can do with this content</li>
            </ul>
          Your rights and responsibilities, basically – important stuff.
          </div>
          <h4 className="text-xl font-bold">2. When these terms apply</h4>
          <p>
            Read these terms before using our content. Whenever you use our content you agree
            to these terms.
          </p>
          <p>
            If you don’t stick to all these terms then we can suspend or terminate your use of our
            content.
          </p>
          <p>
            But first…
          </p>
          <h4 className="text-xl font-bold">3. What’s all this about ‘content’?</h4>
          <div>
            That’s a term used in the media industry meaning anything that the BBC makes
            available through the ‘RemArc’ project, including:
            <ul className="py-2 ml-10 list-disc">
              <li>Images</li>
              <li>TV and radio shows</li>
              <li>Text</li>
              <li>Audio</li>
              <li>Video</li>
              <li>Games</li>
              <li>Software</li>
              <li>Technical stuff such as metadata and open-source code (please see point 7).</li>
            </ul>
          </div>
          <h4 className="text-xl font-bold">4. Terms for using our content</h4>
          <p>
            A few rules to stop you (and us) getting in trouble.
          </p>
          <h4 className="text-xl font-bold">a) Don’t mess with our content</h4>
          <div>
            What do we mean by that? This sort of thing:
            <ul className="py-2 ml-10 list-disc">
              <li>Removing or altering BBC logos, and copyright notices from the content (if there are any)</li>
              <li>Not removing content from your device or systems when we ask you to. This might
              happen when we take down content either temporarily or permanently, which we
            can do at any time, without notice.</li>
            </ul>
          </div>
          <h4 className="text-xl font-bold">b) Don’t use our content for harmful or offensive purposes</h4>
          <div>
            Here’s a list of things that may harm or offend:
            <ul className="py-2 ml-10 list-disc">
              <li>Insulting, misleading, discriminating or defaming (damaging people’s reputations)</li>
              <li>Promoting pornography, tobacco or weapons</li>
              <li>Putting children at risk</li>
              <li>Anything illegal. Like using hate speech, inciting terrorism or breaking privacy law</li>
              <li>Anything that would harm the BBC’s reputation</li>
              <li>Using our content for political or social campaigning purposes or for fundraising.</li>
            </ul>
          </div>
          <h4 className="text-xl font-bold">c) Don’t make it look like our content costs money</h4>
          <p>
            If you put our content on a site that charges for content, you have to say it is free-to-view.
          </p>
          <h4 className="text-xl font-bold">d) Don’t make our content more prominent than non-BBC content</h4>
          <p>
            Otherwise it might look like we’re endorsing you. Which we’re not allowed to do.
          </p>
          <p>
            Also, use our content alongside other stuff (e.g. your own editorial text). You can’t
            make a service of your own that contains only our content.
          </p>
          <p>
            Speaking of which…
          </p>
          <h4 className="text-xl font-bold">e) Don’t exaggerate your relationship with the BBC</h4>
          <p>
            You can’t say we endorse, promote, supply or approve of you.
          </p>
          <p>
            And you can’t say you have exclusive access to our content.
          </p>
          <h4 className="text-xl font-bold">f) Don’t associate our content with advertising or sponsorship</h4>
          <div>
            That means you can’t:
            <ul className="py-2 ml-10 list-disc">
              <li>
                Put any other content between the link to our content and the content itself.
                So no ads or short videos people have to sit through
              </li>
              <li>Put ads next to or over it</li>
              <li>Put any ads in a web page or app that contain mostly our content</li>
              <li>Put ads related to their subject alongside our content. So no trainer ads with an image of shoes</li>
              <li>Add extra content that means you’d earn money from our content.</li>
            </ul>
          </div>
          <h4 className="text-xl font-bold">g) Don’t be misleading about where our content came from</h4>
          <p>
            You can’t remove or alter the copyright notice, or imply that someone else made it.
          </p>
          <h4 className="text-xl font-bold">h) Don’t pretend to be the BBC</h4>
          <div>
            That includes:
            <ul className="py-2 ml-10 list-disc">
              <li>Using our brands, trade marks or logos without our permission</li>
              <li>Using or mentioning our content in press releases and other marketing materials</li>
              <li>Making money from our content. You can’t charge people to view our images, for example</li>
              <li>Sharing our content. For example, no uploading to social media sites. Sharing links is OK.</li>
            </ul>
          </div>
          <h4 className="text-xl font-bold">5. What you have to do</h4>
          <div>
            <ul className="py-2 ml-10 list-disc">
              <li>Use the latest version of the content and, where we have it, don’t remove any tagging or tracking</li>
              <li>Make sure it’s displayed accurately</li>
              <li>Add a credit (if it doesn’t already have one)</li>
            </ul>
          </div>
          <p>
            Most come with credits included. If not, put one of this in a prominent place nearby to
            show where you got the content from:
          </p>
          <p>
            bbc.co.uk – © copyright [the year goes here] BBC
          </p>
          <p>
            If possible, add a hyperlink to the content’s original location. Make sure it works, and
            don’t put anything between the credit and the link.
          </p>
          <h4 className="text-xl font-bold">6. A thing we have to say</h4>
          <p>
            Apart from what we’re responsible for when there’s a mishap, we’re not liable for
            anything that happens to you if you use our content.
          </p>
          <h4 className="text-xl font-bold">7. Using BBC content</h4>
          <div>
            Provided you keep to these rules, the BBC grants you permission to use the BBC
            content but only…
            <ul className="py-2 ml-10 list-disc">
              <li>For non-commercial, personal or research purposes (for example, including the
              content on a non-commercial, advertisement-free reminiscence website aimed at
            helping trigger memories in people with dementia)</li>
              <li>For formal education purposes while you are a student or a member of staff of a
              school, college or university (for example, if you are enrolled on a university or
              college course, or if you are a school pupil, or you are a teacher and you wish to
              display the content on an electronic whiteboard, including images in a printed class
            worksheet)</li>
            </ul>
          </div>
          <h4 className="text-xl font-bold">8. Using BBC content for business</h4>
          <h4 className="text-xl font-bold">a) When you need permission</h4>
          <div>
            If you’re intending to use it for any other purpose, for example…
            <ul className="py-2 ml-10 list-disc">
              <li>substantially to do your job – as an employee, contractor or consultant</li>
              <li>for commercial purposes – to make a profit</li>
              <li>for non-profit and government organisations</li>
              <li>to do text and data mining, computer analysis or to develop or train artificial intelligence</li>
            </ul>
          …you’ll need to get our permission first, and you might have to pay a fee.
          </div>
          <p>
            Find out more about getting permission to use BBC content at&nbsp;
            <a href="https://www.bbc.co.uk/usingthebbc/terms/can-i-use-bbc-content/" className="underline">
              https://www.bbc.co.uk/usingthebbc/terms/can-i-use-bbc-content/
            </a>
          </p>
          <p>
            Pro Sound Effects operate the commercial licensing of BBC sound effects. If you wish to use any 
            sound effects commerically, you can click the <strong>&apos;Buy sound&apos;</strong> button 
            in the&nbsp; <strong>&apos;Show details&apos;</strong> section of any listed sound effect.
          </p>
          <h4 className="text-xl font-bold">9. Metadata</h4>
          <h4 className="text-xl font-bold">a) For people</h4>
          <p>
            You are allowed to use the metadata associated with this content in accordance with
            this Open Government Licence&nbsp;
            <a href="http://www.nationalarchives.gov.uk/doc/opengovernment-licence/version/3/" className="underline">
              http://www.nationalarchives.gov.uk/doc/opengovernment-licence/version/3/
            </a>
          </p>
          <h4 className="text-xl font-bold">b) For business</h4>
          <p>
            You are allowed to use the metadata associated with this content in accordance with
            this Open Government Licence&nbsp;
            <a href="http://www.nationalarchives.gov.uk/doc/opengovernment-licence/version/3/" className="underline">
              http://www.nationalarchives.gov.uk/doc/opengovernment-licence/version/3/
            </a>
          </p>
          <p>
            (The Open Government Licence applies only to the metadata, not the content.)
          </p>
          <h4 className="text-xl font-bold">10. Mishaps</h4>
          <p>
            We take great care to make our content the best it can be. So if something does go
            wrong, we are responsible only:
          </p>
          <p>
            a) If our content damages your device or anything on it. Should this happen, you might
            be able to ask for compensation under consumer protection law.
          </p>
          <p>
            Compensation isn’t guaranteed, though. Be sure to get legal advice.
          </p>
          <p>b) For certain unlikely events. If our negligence causes death or injury, for example.</p>
          <p>c) If you’re an individual “consumer” and it would be unfair for us to not be held
        responsible.</p>
          <div>
            Otherwise, we’re not liable for anything that happens if:
            <ul className="py-2 ml-10 list-disc">
              <li>You rely on advice, data, commentary, opinions or any other content</li>
              <li>There are errors, omissions, interruptions, delays, bugs or viruses</li>
              <li>We turn off or remove content, services, external links or creations (we’d normally
            only do this for legal reasons)</li>
              <li>The thing that happens couldn’t reasonably have been foreseen</li>
              <li>The thing that happens wouldn’t usually result from the mishap or</li>
              <li>You and we hadn’t agreed that this thing would probably happen in the event of a
            mishap.</li>
            </ul>
        This applies to sites we link to as well as our content and services.
          </div>
          <p>
            Speaking of which…
          </p>
          <h4 className="text-xl font-bold">11. External links</h4>
          <p>We sometimes link to non-BBC sites. And we sometimes put our content on them.</p>
          <p>A few things to bear in mind:</p>
          <p>a) We don’t endorse the sites we link to or put our content on.</p>
          <p>b) We’re not responsible for their content or liable for anything that happens to you if
        you use them.</p>
          <p>c) If you or anyone else shares something containing a link, we’re not responsible for
        anything on the site it links to.</p>
          <p>d) External sites usually have their own terms of use.</p>
          <h4 className="text-xl font-bold">12. Final stuff</h4>
          <p>
            A quick recap, a few extra legal bits and we’re done:
          </p>
          <p>a) If you use content on behalf of a school, college or university, that school, college or
        university agrees to these terms.</p>
          <div>
            b) As we said earlier, read these terms before using our content.
            When you use our content, you’re agreeing to:
            <ul className="py-2 ml-10 list-disc">
              <li>These terms of use</li>
              <li>Any other terms we’ve let you know about</li>
            </ul>
          And those things replace all previous agreements between you and us about using
          our content.
          </div>
          <p>c) This is a contract between you and us. No one else has any rights to enforce its
        terms.</p>
          <p>d) English law governs these terms, and only English courts can make judgments about
        them.</p>
          <p>e) Our services and content are made available to you by the British Broadcasting
        Corporation, Broadcasting House, Portland Place, London W1A 1AA.</p>
        </div>
      </div>
    </>
  );
}

export default Licensing;
