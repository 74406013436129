import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { parse } from 'query-string';

import { AppContext } from '../../Context/AppContext';
import { MixerContext } from '../../Context/MixerContext';
import useCustomSnackbars from '../../hooks/useCustomSnackbars';

const MixImport = ({ history }) => {
  const { echoTrack } = useContext(AppContext);
  const { importMixerAssets } = useContext(MixerContext);
  const { openErrorSnackbar } = useCustomSnackbars();

  useEffect(() => {
    const values = parse(history.location.search);

    if (values.mixstring) {
      echoTrack('mix_shared');

      try {
        const checkDecodedString = window.atob(values.mixstring);

        if (checkDecodedString.includes('simplifiedMixerAssets')) {
          importMixerAssets(JSON.parse(checkDecodedString).simplifiedMixerAssets);
        } else {
          // Invalid URL
          openErrorSnackbar('Please check the shared URL and try again');
        }

      } catch (e) {
        openErrorSnackbar('Please check the shared URL and try again');
      }
    }

    history.push('/search');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, importMixerAssets, openErrorSnackbar]);

  return (
    <div className="max-w-screen-xl min-h-screen px-3 mx-auto">
      <h2 className="py-5 text-3xl font-bold md:py-10">Importing Mix...</h2>
    </div>
  );
};

MixImport.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }),
};

export default withRouter(MixImport);
