import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from '../../Context/AppContext';

const FacebookIcon = ({ className }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 155.139 155.139">
    <path d="M89.584,155.139V84.378h23.742l3.562-27.585H89.584V39.184 c0-7.984,2.208-13.425,13.67-13.425l14.595-0.006V1.08C115.325,0.752,106.661,0,96.577,0C75.52,0,61.104,12.853,61.104,36.452 v20.341H37.29v27.585h23.814v70.761H89.584z" />
  </svg>
);

FacebookIcon.propTypes = {
  className: PropTypes.string,
};

const TwitterIcon = ({ className }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 512 512">
    <path d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016 c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992 c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056 c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152 c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792 c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44 C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568 C480.224,136.96,497.728,118.496,512,97.248z" />
  </svg>
);

TwitterIcon.propTypes = {
  className: PropTypes.string,
};

function SocialIcons({ className, shareUrl, shareMetadata }) {
  const { echoTrack } = useContext(AppContext);

  const handleShareClick = (provider) => {
    echoTrack('sharebuttons', { action: `share_${provider}`, data: { ...shareMetadata } }, 'click');
  };

  return (
    <>
      <a
        className="p-3 rounded-full cursor-pointer focus:outline-none hover:opacity-50 focus:opacity-50"
        style={{ backgroundColor: '#3B5998' }}
        href={`https://facebook.com/sharer/sharer.php?u=${shareUrl}`}
        onClick={() => handleShareClick('facebook')}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Share on Facebook"
      >
        <FacebookIcon className={className} />
      </a>
      <a
        className="p-3 rounded-full cursor-pointer focus:outline-none hover:opacity-50 focus:opacity-50"
        style={{ backgroundColor: '#1DA1F2' }}
        href={`https://twitter.com/share?url=${shareUrl}`}
        onClick={() => handleShareClick('twitter')}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Share on Twitter"
      >
        <TwitterIcon className={className} />
      </a>
    </>
  );
}

SocialIcons.defaultProps = {
  className: 'w-6 h-6 text-black',
  shareUrl: '',
  shareMetadata: {},
};

SocialIcons.propTypes = {
  className: PropTypes.string,
  shareUrl: PropTypes.string,
  shareMetadata: PropTypes.shape({})
};

export default SocialIcons;
