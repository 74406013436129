import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import modifyExistingQuerystring from '../../utilities/modifyExistingQuerystring';
import { AppContext } from '../../Context/AppContext';

import { THEME_COLOURS, CATEGORY_LIST } from '../../constants';
import GelIcon from '../GelIcon';
import FileMetadata from '../FileMetadata';
import { SET_MIXER_OPEN } from '../../Context/actionTypes';
import ProSoundInfoModal from './ProSoundInfoModal';

function MoreDetails({ history, asset, mixerVersion }) {
  const { echoTrack, dispatch } = useContext(AppContext);

  const [showProSoundInfoModal, setShowProSoundInfoModal] = useState(false);

  const handleCategoryClick = (category) => {
    echoTrack('audioplayer', { action: 'category_clicked', data: { assetId: asset.id, category } }, 'click');

    if (mixerVersion) {
      dispatch({ type: SET_MIXER_OPEN, value: false });
    }
    history.push(`/search?${modifyExistingQuerystring('cat', category)}`);
  };

  const handleLinkoutClick = () => {
    echoTrack('audioplayer', { action: 'prosounds_linkout', data: { assetId: asset.id } }, 'click');
  };

  const getSubCategoryString = (category) => {
    const subCategory =
      asset.subCategories ? asset.subCategories.find(subCat => subCat.category === category.toLowerCase()) : null;
    return subCategory ? `${category} / ${subCategory.className}` : category;
  };

  const getProsoundUrl = (description) => {

    const descriptionParameter = encodeURI(description.replace(/[A-Za-z0-9\s&]*?\s-\s/, '')).replace('&', '%26');
    return `https://download.prosoundeffects.com/#!explorer?s=${descriptionParameter}&custom_17%5B%5D=BBC%20Complete`;
  };

  return (
    <div className={`flex flex-col items-center py-4 border-t border-gray-400 md:flex-row ${mixerVersion ? 'px-6 md:px-0' : 'px-6 lg:px-0'}`}>
      <FileMetadata placement="block mb-2 md:hidden" asset={asset} />
      <div className="flex flex-col justify-between w-full md:flex-row">
        <div className="w-full md:w-1/3 lg:w-1/2">
          {
            asset.categories.length > 0 && asset.categories.map((assetCategory) => {
              if (CATEGORY_LIST.map(category => category.name).includes(assetCategory.className)) {
                return (
                  <button
                    type="button"
                    key={assetCategory.className}
                    onClick={() => handleCategoryClick(assetCategory.className)}
                    className={`px-5 py-1 mb-1 mr-2 text-xs font-bold border rounded-full focus:outline-none ${mixerVersion ? 'bg-gray-900 border-white hover:bg-white hover:text-gray-900 focus:bg-white focus:text-gray-900' : 'bg-white border-teal-700 text-teal-700 hover:bg-teal-700 hover:text-white focus:bg-teal-700 focus:text-white'}`}
                    {...asset.subCategories.length > 0 && {
                      title: getSubCategoryString(assetCategory.className).replace('_', ' ')
                    }}
                  >
                    {assetCategory.className.replace('_', ' ')}
                  </button>
                );
              }
              return null;
            })
          }
        </div>
        <div className="flex w-full md:w-2/3 lg:w-1/2 md:flex-row">
          <FileMetadata placement="hidden md:block" asset={asset} mixerVersion={mixerVersion} />
          <div className="w-full">
            <div className="flex flex-row justify-end">
              <a
                href={getProsoundUrl(asset.description)}
                onClick={handleLinkoutClick}
                target="_blank"
                rel="noopener noreferrer"
                className={`flex flex-row items-center justify-between h-10 p-2 text-sm font-semibold border ${mixerVersion ? 'text-white border-white' : 'text-blue-800 border-blue-800'} focus:outline-none focus:bg-gray-200 hover:bg-gray-600`}
              >
                <GelIcon name="external-link" fill={mixerVersion ? 'white' : THEME_COLOURS.TERTIARY} className="w-4 h-4 mr-2" />
                <span>Buy sound</span>
              </a>
            </div>
            <div className="flex flex-row justify-end mt-2">
              <button
                type="button"
                onClick={() => setShowProSoundInfoModal(true)}
                className={`flex flex-row items-center justify-between text-sm ${mixerVersion ? 'text-white' : 'text-blue-800'} font-bold focus:outline-none hover:underline focus:underline`}
              >
                <GelIcon name="help" fill={mixerVersion ? 'white' : THEME_COLOURS.TERTIARY} className="w-4 h-5 mr-2" />
                <span>What&apos;s this?</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {showProSoundInfoModal && (<ProSoundInfoModal setShowProSoundInfoModal={setShowProSoundInfoModal} />)}
    </div>
  );
}

MoreDetails.propTypes = {
  asset: PropTypes.object,
  history: PropTypes.object,
  mixerVersion: PropTypes.bool,
};

export default withRouter(MoreDetails);
