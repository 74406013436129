import React, { useEffect, useReducer, createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import Cookie from 'js-cookie';

import AppReducer from './AppReducer';
import {
  GET_SFX_DATA_ASYNC,
  GET_SFX_DATA_SUCCESS,
  GET_SFX_DATA_ERROR,
  GET_CATEGORY_AGGREGATIONS_ASYNC,
  GET_CATEGORY_AGGREGATIONS_SUCCESS,
  GET_CATEGORY_AGGREGATIONS_ERROR,
  GET_CONTINENT_AGGREGATIONS_ASYNC,
  GET_CONTINENT_AGGREGATIONS_SUCCESS,
  GET_CONTINENT_AGGREGATIONS_ERROR,
  GET_DURATION_AGGREGATIONS_ASYNC,
  GET_DURATION_AGGREGATIONS_SUCCESS,
  GET_DURATION_AGGREGATIONS_ERROR,
  CLEAR_SEARCH_RESULTS,
  ECHO_ANALYTICS_INITIALISE,
  SET_SELECTED_ASSET,
  TOGGLE_RATING_MODAL,
  TOGGLE_AUTOPLAY
} from './actionTypes';

import initialState from './initialState';
import {
  API_URL,
  VERSION,
  ENVIRONMENT,
  COOKIE_RATING_SUBMITTED
} from '../constants';
import processDescriptions from '../utilities/processDescriptions';
import { FavouritesContext } from './FavouritesContext';
import queryBuilder from '../utilities/queryBuilder';
import useCustomSnackbars from "../hooks/useCustomSnackbars";
import getQuerystring from '../utilities/getQuerystring';

export const AppContext = createContext(initialState);

// Provider component
export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  const { state: { favouriteIds } } = useContext(FavouritesContext); // Necessary for aggregations
  const { openErrorSnackbar } = useCustomSnackbars();

  // Clears search results from state
  function clearSearchResults() {
    dispatch({ type: CLEAR_SEARCH_RESULTS });
  }

  function echoTrack(pageName, eventData = null, actionName) {
    if (!state.echo) {
      // eslint-disable-next-line
      console.warn('Echo still initialising.');
      return false;
    }

    // If an 'actionName' is specified (click, submit etc) but no eventData.action, don't fire
    if ((actionName && !eventData) || (actionName && !eventData.action)) return false;

    return actionName
      ? state.echo.userActionEvent(
        // actionName = 'click', 'submit' etc
        actionName,
        // action = 'clicked_asset', 'submit_report_form' etc
        eventData.action,
        {
          // container = page name e.g. 'home', 'searchresults' etc
          container: pageName,
          // metadata = data about the 'action' e.g. '{"assetId": "xxxx", "title": "Asset Title"}'. Serialized.
          metadata: eventData.data ? JSON.stringify(eventData.data) : null,
        },
      )
      : state.echo.viewEvent(
        // page name
        `sfx.${pageName}`,
        // additional event data, if any
        eventData && {
          event_data: (eventData === 'object' && eventData !== null) ? JSON.stringify(eventData) : eventData,
        },
      );
  }

  function searchByQuery(queryOverride) {
    const parsedQueryString = getQuerystring();
    const parsedQueryStringKeys = Object.keys(parsedQueryString);
    const isBlankSearch = parsedQueryStringKeys.length === 0 && !queryOverride;
    const isSingleCategorySearch = parsedQueryStringKeys.length === 1 && parsedQueryString.cat && parsedQueryString.cat.split(',').length === 1 && !queryOverride;

    let criteria = queryBuilder();

    // Override the search query if provided
    // Favourites page uses this to pass through favouriteIds from localStorage
    if (queryOverride) {
      criteria = {
        ...criteria,
        ...queryOverride
      };
    }

    echoTrack('searchquery', { action: 'search_query', data: { query: criteria } }, 'click');

    let searchUrl = `${API_URL}/api/sfx/search`;

    // If single category or blank search, use cached GET endpoints, otherwise perform normal POST search
    // Ignore if a queryOverride value has been passed through
    if (!queryOverride) {
      if (isBlankSearch) {
        searchUrl = `${API_URL}/api/sfx/cached/search`;
      }

      if (isSingleCategorySearch) {
        searchUrl = `${API_URL}/api/sfx/cached/categorysearch/${criteria.categories[0]}`;
      }
    }

    dispatch({
      type: GET_SFX_DATA_ASYNC,
    });

    fetch(searchUrl, isSingleCategorySearch || isBlankSearch ? {} : {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ criteria }),
    })
      .then((res) => res.json())
      .then((data) => dispatch({
        type: GET_SFX_DATA_SUCCESS,
        results: data.results.map(result => processDescriptions(result)),
        resultsLength: data.total,
      }))
      .catch((err) => {
        openErrorSnackbar('An error occurred when trying to fetch your search results. Please try again.', 4000);

        dispatch({
          type: GET_SFX_DATA_ERROR,
          err,
        });
      });
  }

  function getAggregations(pathname, type) {
    const criteria = queryBuilder();

    // If in favourites, override the query term and use the favourites from localStorage
    if (pathname === '/favourites') {
      criteria.query = favouriteIds ? `"${favouriteIds.join(`","`)}"` : '';
    }

    const AGGREGATION_TYPES = {
      categories: {
        reducerField: 'categoryAggregations',
        endpoint: `/categoryAggregations`,
        actionTypes: [
          GET_CATEGORY_AGGREGATIONS_ASYNC,
          GET_CATEGORY_AGGREGATIONS_SUCCESS,
          GET_CATEGORY_AGGREGATIONS_ERROR,
        ]
      },
      continents: {
        reducerField: 'continentAggregations',
        endpoint: `/continentAggregations`,
        actionTypes: [
          GET_CONTINENT_AGGREGATIONS_ASYNC,
          GET_CONTINENT_AGGREGATIONS_SUCCESS,
          GET_CONTINENT_AGGREGATIONS_ERROR,
        ]
      },
      durations: {
        reducerField: 'durationAggregations',
        endpoint: `/durationAggregations`,
        actionTypes: [
          GET_DURATION_AGGREGATIONS_ASYNC,
          GET_DURATION_AGGREGATIONS_SUCCESS,
          GET_DURATION_AGGREGATIONS_ERROR,
        ]
      }
    }[type];

    dispatch({ type: AGGREGATION_TYPES.actionTypes[0] });

    fetch(`${API_URL}/api/sfx${AGGREGATION_TYPES.endpoint}`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ criteria }),
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: AGGREGATION_TYPES.actionTypes[1],
          [AGGREGATION_TYPES.reducerField]: data.aggregations
        });
      })
      .catch((err) => dispatch({
        type: AGGREGATION_TYPES.actionTypes[2],
        err,
      }));
  }

  function getCachedCategoryAggregations() {
    dispatch({ type: GET_CATEGORY_AGGREGATIONS_ASYNC });

    fetch(`${API_URL}/api/sfx/cached/categoryaggregations`)
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: GET_CATEGORY_AGGREGATIONS_SUCCESS,
          categoryAggregations: data.aggregations
        });
      })
      .catch((err) => dispatch({
        type: GET_CATEGORY_AGGREGATIONS_ERROR,
        err,
      }));
  }

  function initialiseEchoAnalytics() {
    // Initialise Echo client, including default client data
    window.require(['echo-lib'], (EchoLib) => {
      const { Enums, ConfigKeys } = EchoLib; // Enums

      const echo = new EchoLib.EchoClient(
        'sfx', // App Name
        Enums.ApplicationType.WEB, // App Type
        {
          [ConfigKeys.ATI_ENABLED]: true,
          [ConfigKeys.DESTINATION]: ENVIRONMENT === 'production'
            ? Enums.Destinations.REWIND_PS
            : Enums.Destinations.REWIND_PS_TEST,
          [ConfigKeys.REPORTING_PROFILE]: 'GNL',
        },
      );

      echo.setProducer(Enums.Producers.OTHER);
      // set the version of the application
      echo.setAppVersion(VERSION);

      if (!state.echo) {
        dispatch({ type: ECHO_ANALYTICS_INITIALISE, echo });
      } else {
        dispatch(echoTrack('loadsite', 'load-success'));
      }
    });
  }

  function setSelectedAsset(asset) {
    dispatch({
      type: SET_SELECTED_ASSET,
      asset,
    });
  }

  function submitRating(rating) {
    echoTrack('rating', { action: 'submitted_rating', data: { rating } }, 'click');

    Cookie.set(COOKIE_RATING_SUBMITTED, true, { expires: 30 });
  }

  function toggleRatingModal(val) {
    dispatch({ type: TOGGLE_RATING_MODAL, val });
  }

  function toggleAutoplay() {
    dispatch({ type: TOGGLE_AUTOPLAY });
  }

  // Initialise Echo if not initialised already
  useEffect(() => {
    initialiseEchoAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.echo]);

  const ctx = useMemo(() => ({
    state,
    dispatch,
    searchByQuery,
    getAggregations,
    getCachedCategoryAggregations,
    clearSearchResults,
    echoTrack,
    setSelectedAsset,
    submitRating,
    toggleRatingModal,
    toggleAutoplay,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [state]);

  return (
    <AppContext.Provider value={ctx}>
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.shape({})
};

export function withContext(Component) {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <AppContext.Consumer>{(context) => <Component {...props} context={context} />}</AppContext.Consumer>
  );
}
