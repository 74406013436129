/* eslint-disable no-undef */
const packageJson = require('../package.json');

const appUrls = {
  local: {
    client: 'http://local.bbc.co.uk:3000',
    api: 'http://localhost:3001/dev',
    batchDownload: 'ws://localhost:4001'
  },
  development: {
    client: 'http://local.bbc.co.uk:3000',
    api: 'https://sound-effects-api-dev.bbcrewind.co.uk',
    batchDownload: 'wss://kypd0uek3b.execute-api.eu-west-1.amazonaws.com/dev',
  },
  staging: {
    client: 'https://sound-effects-staging.bbcrewind.co.uk',
    api: 'https://sound-effects-api-staging.bbcrewind.co.uk',
    batchDownload: 'wss://169kaitchf.execute-api.eu-west-1.amazonaws.com/staging',
  },
  production: {
    client: 'https://sound-effects.bbcrewind.co.uk',
    api: 'https://sound-effects-api.bbcrewind.co.uk',
    batchDownload: 'wss://9qykiiihp2.execute-api.eu-west-1.amazonaws.com/prod',
  }
};

export const VERSION = packageJson.version;
export const ENVIRONMENT = process.env.REACT_APP_APP_ENV || 'development';

export const APP_URL = appUrls[ENVIRONMENT].client;
export const API_URL = appUrls[ENVIRONMENT].api;
export const BATCH_DOWNLOAD_FUNCTION = appUrls[ENVIRONMENT].batchDownload;
export const MEDIA_BASE_URL = ENVIRONMENT === 'production' ? 'https://sound-effects-media.bbcrewind.co.uk' : 'https://sound-effects-media-staging.bbcrewind.co.uk'; // Use staging CDN unless prod
export const MEDIA_LOW_QUALITY_URL = `${MEDIA_BASE_URL}/mp3`;
export const MEDIA_HIGH_QUALITY_URL = `${MEDIA_BASE_URL}/zip`;
export const WAVEFORM_URL = `${MEDIA_BASE_URL}/waveform`;

export const CATEGORY_LIST = [
  {
    name: 'Aircraft',
    icon: 'commercial-plane',
  },
  {
    name: 'Animals',
    icon: 'paw'
  },
  {
    name: 'Applause',
    icon: 'applause',
    viewBox: '0 0 66 68',
  },
  {
    name: 'Atmosphere',
    icon: 'in-season',
  },
  {
    name: 'Bells',
    icon: 'notifications',
  },
  {
    name: 'Birds',
    icon: 'bird',
    viewBox: '0 0 66 57',
  },
  {
    name: 'Clocks',
    icon: 'clock',
  },
  {
    name: 'Comedy',
    icon: 'comedy',
  },
  {
    name: 'Crowds',
    icon: 'group',
  },
  {
    name: 'Daily_Life',
    icon: 'platform-game',
  },
  {
    name: 'Destruction',
    icon: 'disruption',
  },
  {
    name: 'Electronics',
    icon: 'electronics',
    viewBox: '0 0 66 76',
  },
  {
    name: 'Events',
    icon: 'events',
  },
  {
    name: 'Fire',
    icon: 'fire',
  },
  {
    name: 'Footsteps',
    icon: 'footprints',
  },
  {
    name: 'Industry',
    icon: 'briefcase',
  },
  {
    name: 'Machines',
    icon: 'settings',
  },
  {
    name: 'Medical',
    icon: 'health-1',
  },
  {
    name: 'Military',
    icon: 'military',
    viewBox: '0 0 66 76',
  },
  {
    name: 'Nature',
    icon: 'tree',
  },
  {
    name: 'Sport',
    icon: 'sport-general',
  },
  {
    name: 'Toys',
    icon: 'game',
  },
  {
    name: 'Transport',
    icon: 'car'
  },
];

export const CATEGORY_LIST_MOST_POPULAR = ['Nature', 'Transport', 'Machines', 'Daily_Life', 'Military', 'Animals', 'Clocks', 'Sport', 'Footsteps', 'Aircraft', 'Electronics', 'Crowds'];

export const INITIAL_CATEGORIES_MD = 12;
export const INITIAL_CATEGORIES_SM = 4;

export const SORT_OPTIONS = [
  {
    label: 'Relevance',
    sort: 'relevance'
  },
  {
    label: 'Duration (asc)',
    sort: 'duration-asc'
  },
  {
    label: 'Duration (desc)',
    sort: 'duration-desc'
  }
];

export const DURATION_LIST = [
  {
    text: '< 10sec',
    query: '0-9',
  },
  {
    text: '10 - 30sec',
    query: '10-30',
  },
  {
    text: '30sec - 1min',
    query: '30-60',
  },
  {
    text: '1min - 2min',
    query: '60-120',
  },
  {
    text: '2min - 5min',
    query: '120-300',
  },
  {
    text: '5min - 10min',
    query: '300-600',
  },
  {
    text: '10min - 1hr',
    query: '600-3600',
  },
];

export const CONTINENT_LIST = [
  'Africa',
  'Antarctica',
  'Asia',
  'Europe',
  'North America',
  'Oceania',
  'South America'
];

export const SOURCES = {
  bbc_archive: {
    text: 'BBC Sound Effects',
  },
  natural_history_unit: {
    text: 'Natural History Unit',
  },
};

export const LOCALSTORAGE_FAVOURITES = 'bbcsfx-favourites';
export const LOCALSTORAGE_ACCEPT_FAVOURITES_LIMIT = 'bbcsfx-accept-favourites-limit';

export const COOKIES_EXPIRY = 395; // days, approx equivalent to bbc.co.uk
export const COOKIE_TERMS_AGREEMENT = 'bbcsfx-download-terms-agreed';
export const COOKIE_RATING_SUBMITTED = 'bbcsfx-rating-submitted';
export const COOKIE_BANNER_ACCEPTED = 'bbcsfx-accepted-cookies';
export const COOKIE_FAVOURITES_BANNER_ACCEPTED = 'bbcsfx-favourites-banner-dismissed';

export const DEFAULT_PAGE_ROUTE = '/';

// Rating modal
export const RATING_MODAL_TIMER = 180000; // 3 minutes

export const RATING_VALUES = [{
  value: 1,
  icon: 'rating-sad',
  title: 'No'
}, {
  value: 3,
  icon: 'rating-neutral',
  title: 'Somewhat'
}, {
  value: 5,
  icon: 'rating-happy',
  title: 'Yes'
}];

// Useful for GEL icons
export const THEME_COLOURS = {
  PRIMARY: '#333E48',
  SECONDARY: '#007E8F',
  TERTIARY: '#3A549C',
  OFF_WHITE: '#F9F9F9', // Off White
  MEDIUM_GREY: '#E3E3E3', // Medium Gray
  GREY: '#CECECE', // Gray
  DARK_GREY: '#C8C8C8', // Dark Gray
  BLUE_GREY: '#CFD5DA', // Blue Gray
  NAVY_GREY: '#848B90', // Navy Gray 
  WARNING: '#8F0000', // Dark red
};

export const RESULT_SIZE_CONFIG = {
  incrementSize: 10,
  sizeLimit: 300
};

export const DOWNLOAD_SEGMENT_CONFIG = {
  segmentSize: 100,
};

export const FAVOURITES_CONFIG = {
  sizeLimit: 100
};

export const MIXER_CONFIG = {
  sizeLimit: 100,
};

export const DEFAULT_SNACKBAR_OPTIONS = {
  position: 'top-right',
  style: {
    backgroundColor: THEME_COLOURS.SECONDARY,
    fontFamily: 'ReithSans, sans-serif',
    fontWeight: 'bold',
  },
};

export const ERROR_SNACKBAR_OPTIONS = {
  ...DEFAULT_SNACKBAR_OPTIONS,
  style: {
    ...DEFAULT_SNACKBAR_OPTIONS.style,
    backgroundColor: THEME_COLOURS.WARNING,
  },
};