import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from '../../Context/AppContext';
import { MixerContext } from '../../Context/MixerContext';

import BaseModal from '../../Components/BaseModal';
import GelIcon from '../../Components/GelIcon';
import SocialIcons from '../../Components/SocialIcons';

import { APP_URL, THEME_COLOURS } from '../../constants';

function ShareMixModal({ setShowShareModal }) {
  const { echoTrack } = useContext(AppContext);
  const { state: { mixerAssets } } = useContext(MixerContext);

  const inputRef = useRef(null);

  const [encodedString, setEncodedString] = useState(null);
  const [copyText, setCopyText] = useState('Copy');

  // Encode mixer assets on mount
  useEffect(() => {
    try {
      const mixerAssetKeys = Object.keys(mixerAssets);
      const simplifiedMixerAssets = {};

      // Just save key, id and any settings
      mixerAssetKeys.forEach(key => {
        simplifiedMixerAssets[key] = {
          id: mixerAssets[key].id,
          mixerSettings: mixerAssets[key].mixerSettings,
        };

        // Don't encode empty settings
        if (!mixerAssets[key].mixerSettings) {
          delete simplifiedMixerAssets[key].mixerSettings;
        }
      });

      setEncodedString(window.btoa(JSON.stringify({ simplifiedMixerAssets })));
    } catch (e) {
      setShowShareModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixerAssets]);

  const handleCopy = () => {
    echoTrack('mixer', { action: 'mixer_share_copied' }, 'click');

    inputRef.current.removeAttribute('disabled');

    inputRef.current.select();
    inputRef.current.setSelectionRange(0, 99999);

    document.execCommand("copy");

    inputRef.current.setAttribute('disabled', true);

    setCopyText('Copied');
  };

  return (
    <BaseModal labelId="sharemix_modal_title" descriptionId="sharemix_modal_desc">
      <p className="sr-only" id="sharemix_modal_desc">A tool to share your favourites with either a URL or to social media.</p>
      <div className="px-6 py-8 text-left sm:px-8">
        <div className="flex items-center justify-between pb-3">
          <div className="flex flex-row items-center space-x-3">
            <GelIcon name="share" fill={THEME_COLOURS.PRIMARY} className="w-4 h-4" />
            <span className="text-2xl font-bold text-gray-900" id="sharemix_modal_title">Share Mix</span>
          </div>
          <button type="button" className="p-2 rounded-md focus:outline-none focus:bg-gray-300 hover:bg-gray-300" onClick={() => setShowShareModal(false)} aria-label="Close">
            <GelIcon name="close" fill={THEME_COLOURS.PRIMARY} />
          </button>
        </div>
        <div>
          <p className="mb-3 text-gray-900">Share link</p>
          <div className="relative flex flex-row items-center">
            <div className="absolute top-0 flex flex-col justify-center h-full ml-3">
              <GelIcon name="link" fill={THEME_COLOURS.PRIMARY} className="w-3 h-3 opacity-75" />
            </div>
            <label className="sr-only" htmlFor="share-mix">Share mix</label>
            <input
              id="share-mix"
              ref={inputRef}
              className="w-full py-3 pl-8 pr-2 text-gray-900 bg-gray-100"
              disabled
              type="text"
              value={`${APP_URL}/miximport?mixstring=${encodedString}`}
            />
            <button type="button" className="px-4 py-3 font-bold text-white bg-gray-900 focus:outline-none hover:opacity-75 focus:opacity-75" onClick={handleCopy}>{copyText}</button>
          </div>
        </div>
        <div className="flex flex-row items-center justify-end mt-8 space-x-3 sm:justify-start">
          <SocialIcons shareUrl={`${APP_URL}/miximport?mixstring=${encodedString}`} className="w-6 h-6 text-white" />
        </div>
      </div>
    </BaseModal>
  );
}

ShareMixModal.propTypes = {
  setShowShareModal: PropTypes.func
};

export default ShareMixModal;