
import { CATEGORY_LIST, DURATION_LIST, CONTINENT_LIST, SOURCES } from '../constants';
export const parseCategories = (categoryString) => {
  const parsedCats = categoryString ? categoryString.split(',') : [];
  return parsedCats.filter(cat =>
    CATEGORY_LIST.find(category => category.name.toLowerCase() === cat.toLowerCase()));
};
export const parseDurations = (durationString) => {
  const parsedDurations = durationString ? durationString.split(',') : [];
  return parsedDurations.filter(duration =>
    DURATION_LIST.find(listDur => listDur.query === duration));
};
export const parseSources = (sourceString) => {
  const parsedSources = sourceString ? sourceString.split(',') : [];
  return parsedSources.filter(source => Object.keys(SOURCES).includes(source));
};
export const parseContinents = (continentString) => {
  const parsedContinents = continentString ? continentString.split(',') : [];
  return parsedContinents.filter(continent => CONTINENT_LIST.includes(continent));
};
