// Parses the URL and returns the querystring params as an object

const SOUND_ABBREVIATIONS = [
  { regex: /\bb\/g\b|\bbg\b/gi, resolved: 'background' },
  { regex: /\bc\/u\b|\bcu\b/gi, resolved: 'close-up' },
  { regex: /\bmcu\b/gi, resolved: 'medium close-up' },
  { regex: /\brec\.\b/gi, resolved: 'rec.' },
  { regex: /\bocc\b/gi, resolved: 'occasional' },
  { regex: /\batmos\b/gi, resolved: 'atmosphere' },
  { regex: /\bmd\b/gi, resolved: 'medium distance' },
  { regex: /\bw\/\b/gi, resolved: 'with' },
  { regex: /\bnb\b/gi, resolved: 'NB' },
];

const capitaliseString = (value) => {
  const strings = value.split(' ');
  return strings.map(string => {
    if (string.startsWith('(')) {
      return `${string.charAt(0)}${string.charAt(1)}${string.slice(2).toLowerCase()}`;
    }
    return `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`;
  }).join(' ');
};

export default (asset) => {
  const updatedAsset = asset;
  // parse description abbreviations
  SOUND_ABBREVIATIONS.forEach(sa =>
    sa.regex.test(updatedAsset.description)
      ? updatedAsset.description = updatedAsset.description.replace(sa.regex, sa.resolved)
      : false);
  // add description prefix string
  if (updatedAsset.source === 'natural_history_unit') {
    updatedAsset.description = `${updatedAsset.additionalMetadata.bandDescription
      ? `${capitaliseString(updatedAsset.additionalMetadata.bandDescription)} - `
      : ''}${updatedAsset.description}`;
  }
  if (updatedAsset.source === 'bbc_archive') {
    updatedAsset.description = `${updatedAsset.additionalMetadata.originalCategory
      ? `${capitaliseString(updatedAsset.additionalMetadata.originalCategory)} - `
      : ''}${updatedAsset.description}`;
  }
  
  return updatedAsset;
};
