import { RESULT_SIZE_CONFIG } from "../constants";
import getQuerystring from "./getQuerystring";

function queryBuilder() {
  // Get query params
  const parsedQuerystring = getQuerystring();

  // Prevent manual URL changes of the result size above the configured 'sizeLimit'
  const resultSizeLimiter = (size) => {
    if (size > RESULT_SIZE_CONFIG.sizeLimit) {
      return RESULT_SIZE_CONFIG.sizeLimit;
    }
    return size;
  };

  return {
    from: 0,
    size: parsedQuerystring.resultSize
      ? resultSizeLimiter(parseInt(parsedQuerystring.resultSize, 10))
      : RESULT_SIZE_CONFIG.incrementSize,
    query: parsedQuerystring.q,
    tags: parsedQuerystring.tags ? parsedQuerystring.tags.split(',') : null,
    categories: parsedQuerystring.cat ? parsedQuerystring.cat.split(',') : null,
    durations: parsedQuerystring.durations ? parsedQuerystring.durations.split(',').map(duration => {
      // Ensure valid format and integer parsing
      if (!/^\d{1,4}?-\d{1,4}?$/.test(duration)) return null;
      const values = duration.split('-').map(i => parseInt(i));
      if (values[0] >= values[1]) return null;
      return {
        min: parseInt(values[0]),
        max: parseInt(values[1]),
      };
    }).filter(duration => !!duration) : null,
    continents: parsedQuerystring.continents ? parsedQuerystring.continents.split(',') : null,
    sortBy: parsedQuerystring.sortBy || null,
    source: parsedQuerystring.source ? parsedQuerystring.source : null,
    recordist: parsedQuerystring.recordist ? parsedQuerystring.recordist : null,
    habitat: parsedQuerystring.habitat ? parsedQuerystring.habitat : null,
  };
}

export default queryBuilder;