import React, { useContext, useState } from 'react';
import { PropTypes } from 'prop-types';
import Cookie from 'js-cookie';

import GelIcon from '../GelIcon';
import ShareModal from '../ShareModal';
import TermsOfUseModal from './TermsOfUseModal';
import { COOKIE_TERMS_AGREEMENT, MEDIA_HIGH_QUALITY_URL, MEDIA_LOW_QUALITY_URL, THEME_COLOURS } from '../../constants';
import { AppContext } from '../../Context/AppContext';

function UserButtons({
  asset,
  favourited,
  handleFavouriteClick,
  showDownloadPopover,
  setShowDownloadPopover,
  mixerVersion,
  showDetails,
  setShowDetails,
}) {
  const { echoTrack } = useContext(AppContext);

  const [showShareModal, setShowShareModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState();

  const handleDownloadFileClick = (qualitySourceUrl, description, fileName, fileType) => {
    const fileRequestUrl =
      `${qualitySourceUrl}/${fileName}.${fileType}?download&rename=BBC_${description.replace(/\W/g, '-').substring(0, 10)}_${fileName.substring(0, 10)}`;
    if (!Cookie.get(COOKIE_TERMS_AGREEMENT)) {
      setSelectedFile(fileRequestUrl);
      setShowTermsModal(true);
    } else {
      window.open(fileRequestUrl);
      echoTrack('audioplayer', { action: 'download_media', data: { assetId: asset.id } }, 'click');
    }

    setShowDownloadPopover(false);
  };

  // Button colours change depending on whether this is in a mixer asset or not
  const selectedBackgroundColour = mixerVersion ? 'bg-white hover:bg-white focus:bg-white rounded-t-lg' : 'bg-gray-900 hover:bg-gray-900 focus:bg-gray-900 rounded-t-lg';
  const backgroundColour = mixerVersion ? 'bg-gray-900 hover:bg-gray-800 focus:bg-gray-800' : 'bg-white hover:bg-gray-300 focus:bg-gray-300';
  const selectedFillColour = mixerVersion ? THEME_COLOURS.SECONDARY : 'white';
  const unselectedFillColour = mixerVersion ? 'white' : THEME_COLOURS.SECONDARY;

  return (
    <div className={`flex flex-row items-center space-x-4 ${mixerVersion ? 'justify-between' : 'w-full'}`}>
      <div className="relative flex-grow">
        <button
          type="button"
          className={`focus:outline-none p-2 -ml-2 rounded-md md:m-0 hover:bg-gray-${!mixerVersion ? '300' : '800'} focus:bg-gray-${!mixerVersion ? '300' : '800'} md:block`}
          onClick={() => setShowShareModal(true)}
          aria-label="Share"
          title="Share"
        >
          <GelIcon name="share" fill={mixerVersion ? 'white' : THEME_COLOURS.TERTIARY} className="w-6 h-6 md:w-5 md:h-5" />
        </button>
      </div>
      {
        showShareModal && (
          <ShareModal asset={asset} setShowShareModal={setShowShareModal} />
        )
      }
      <div className="relative">
        <button
          type="button"
          title={favourited ? 'Remove Favourite' : 'Add Favourite'}
          className={`focus:outline-none p-2 rounded-md hover:bg-gray-${!mixerVersion ? '300' : '800'} focus:bg-gray-${!mixerVersion ? '300' : '800'} md:block`}
          onClick={handleFavouriteClick}
          aria-label={favourited ? 'Remove from favourites' : 'Add to favourites'}
        >
          <GelIcon name={favourited ? 'love-filled' : 'love'} fill={mixerVersion ? 'white' : THEME_COLOURS.TERTIARY} className="w-6 h-6 md:w-5 md:h-5" />
        </button>
      </div>
      <div className="relative">
        <button
          type="button"
          title="Download file"
          onClick={() => setShowDownloadPopover(!showDownloadPopover)}
          className={`${showDownloadPopover ? selectedBackgroundColour : backgroundColour} p-2 rounded-md focus:outline-none`}
          aria-label={showDownloadPopover ? 'Hide download options' : 'Show download options'}
        >
          <GelIcon name="download" className="w-6 h-6 md:w-5 md:h-5" fill={showDownloadPopover ? selectedFillColour : unselectedFillColour} />
        </button>
        {showDownloadPopover && asset.file.small && asset.file.small.name && (
          <>
            <button
              type="button"
              className="fixed top-0 left-0 z-40 w-full h-full bg-transparent cursor-default focus:outline-none focus:focus:outline-none"
              onClick={() => setShowDownloadPopover(false)}
              tabIndex="-1"
            >
              <span className="hidden">Close popup</span>
            </button>
            <div className={`absolute right-0 z-50 w-40 p-2 -mt-2 rounded-lg ${mixerVersion ? 'text-gray-900 bg-white' : 'text-white bg-gray-900'}`}>
              <p className="text-sm font-bold text-left">Download as:</p>
              <p className="flex justify-around text-lg align-middle">
                <button type="button" className="focus:outline-none hover:underline focus:underline" onClick={() => handleDownloadFileClick(MEDIA_HIGH_QUALITY_URL, asset.description, asset.file.small.name, 'wav.zip')}>
                  wav
                </button>
                <span>|</span>
                <button type="button" className="focus:outline-none hover:underline focus:underline" onClick={() => handleDownloadFileClick(MEDIA_LOW_QUALITY_URL, asset.description, asset.file.small.name, 'mp3')}>
                  mp3
                </button>
              </p>
            </div>
          </>
        )}
      </div>
      {showTermsModal && (
        <TermsOfUseModal selectedFile={selectedFile} setShowTermsModal={setShowTermsModal} asset={asset} />
      )}
      {mixerVersion && (
        <div className="relative">
          <button
            type="button"
            className="flex flex-row items-center p-2 rounded-md hover:bg-gray-800 focus:outline-none md:hidden"
            onClick={() => setShowDetails(!showDetails)}
            aria-label={showDetails ? 'Hide details' : 'Show details'}
          >
            <GelIcon name={showDetails ? 'chevron-up' : 'chevron-down'} className="w-6 h-6" fill="white" />
          </button>
        </div>
      )}
    </div>
  );
}

UserButtons.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    file: PropTypes.shape({
      small: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  }).isRequired,
  isLooping: PropTypes.bool.isRequired,
  setIsLooping: PropTypes.func.isRequired,
  favourited: PropTypes.bool.isRequired,
  handleFavouriteClick: PropTypes.func.isRequired,
  showDownloadPopover: PropTypes.bool.isRequired,
  setShowDownloadPopover: PropTypes.func.isRequired,
  mixerVersion: PropTypes.bool.isRequired,
  showDetails: PropTypes.bool,
  setShowDetails: PropTypes.func,
};

export default UserButtons;
