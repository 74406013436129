import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { parse } from 'query-string';

import { AppContext } from '../../Context/AppContext';
import { MixerContext } from '../../Context/MixerContext';
import { FavouritesContext } from '../../Context/FavouritesContext/index.jsx';
import useCustomSnackbars from '../../hooks/useCustomSnackbars';

import ShareModal from './ShareFavouritesModal';
import ImportFavouritesModal from './ImportFavouritesModal';
import SearchResultItem from '../../Components/SearchResultItem';
import MixerOverlay from '../../Components/Mixer/MixerOverlay';
import MixerStickyBar from '../../Components/Mixer/MixerStickyBar';
import ShowMoreResults from '../SearchResults/ShowMoreResults';
import GelIcon from '../../Components/GelIcon';
import BatchDownloadModal from '../../Components/BatchDownloadModal';

function Favourites({ history }) {
  const {
    state: { results, resultsLoading },
    searchByQuery,
    clearSearchResults,
    echoTrack
  } = useContext(AppContext);
  const { state: { mixerModeOn }, setMixerPlaying, toggleMixer } = useContext(MixerContext);
  const { openErrorSnackbar } = useCustomSnackbars();

  const { state: { favouriteIds } } = useContext(FavouritesContext);

  const [showShareModal, setShowShareModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showDownloadPopover, setShowDownloadPopover] = useState(false);
  const [downloadType, setDownloadType] = useState(null);

  // Pass favouriteIds as a concatenated string
  const favouriteIdsString = favouriteIds ? `"${favouriteIds.join(`","`)}"` : '';

  // Scroll to top on mount
  useEffect(() => {
    echoTrack('favourites');

    window.scrollTo(0, 0);

    return () => {
      setMixerPlaying(false);

      if (history.location.pathname !== '/search') {
        toggleMixer(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check the route on mount to see if shared page route
  useEffect(() => {
    const values = parse(history.location.search);

    if (values.favouritesstring) {
      echoTrack('favourites_shared');

      try {
        const checkDecodedString = window.atob(values.favouritesstring);

        // Only show the import modal if the parsed URL param is considered valid
        if (checkDecodedString.includes('favouriteIds')) {
          setShowImportModal(true);
        }
      } catch (e) {
        openErrorSnackbar('Please check the shared URL and try again');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    // Perform a search on mount and when the favourite array changes
    searchByQuery({ query: favouriteIdsString });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favouriteIds]);

  // Perform search when filtering changes
  // Update the aggregrations when filtering changes
  useEffect(() => history.listen((location) => {
    if (location.pathname === '/favourites') {
      searchByQuery({ query: favouriteIdsString });
    } else {
      // Else clear the search results from state as the user is navigating away
      clearSearchResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [history]);

  return (
    <>
      <Helmet title="My Favourites | BBC Sound Effects" />
      {showImportModal && (<ImportFavouritesModal setShowImportModal={setShowImportModal} />)}
      <div className={`${mixerModeOn ? 'bg-gray-700' : 'bg-gray-100'} transition-all duration-200 ease-in-out`}>
        <div className="max-w-screen-xl min-h-screen px-0 mx-auto lg:px-3">
          <div className="flex flex-row items-end justify-end w-full border-t md:py-5">
            {
              results.length > 0 && (
                <div className="flex flex-row items-center md:space-x-4">
                  <button
                    type="button"
                    className="flex flex-row items-center justify-center p-4 space-x-4 font-bold text-white transition-all duration-200 ease-in-out bg-blue-800 focus:outline-none md:px-4 md:py-2 hover:opacity-75 focus:opacity-75"
                    onClick={() => setShowShareModal(true)}
                  >
                    <GelIcon name="share" className="w-5 h-5" fill="white" />
                    <span>Share Favourites</span>
                  </button>
                  {showShareModal && (<ShareModal setShowShareModal={setShowShareModal} />)}
                  <div className="relative">
                    <button
                      type="button"
                      className={`hidden md:flex md:flex-row w-full transition-all ease-in-out duration-200 items-center justify-center p-4 space-x-4 font-bold text-white focus:outline-none ${!showDownloadPopover ? 'bg-teal-700 hover:opacity-75 focus:opacity-75' : 'bg-gray-900 md:rounded-t-lg md:rounded-br-lg shadow-lg'} md:px-4 md:py-2`}
                      onClick={() => setShowDownloadPopover(true)}
                    >
                      <GelIcon name="download" className="w-5 h-5" fill="white" />
                      <span>Download all <span className="hidden md:inline-block">favourites</span></span>
                    </button>
                    {showDownloadPopover && (
                      <>
                        <button
                          type="button"
                          className="fixed top-0 left-0 z-40 w-full h-full bg-transparent cursor-default focus:outline-none focus:focus:outline-none"
                          onClick={() => setShowDownloadPopover(false)}
                          tabIndex="-1"
                        >
                          <span className="hidden">Close popup</span>
                        </button>
                        <div className="absolute left-0 z-50 w-40 p-2 text-white bg-gray-900 rounded-b-lg shadow-lg">
                          <p className="text-sm font-bold text-left">Download as:</p>
                          <p className="flex justify-around text-lg align-middle">
                            <button type="button" className="focus:outline-none hover:underline focus:underline" onClick={() => setDownloadType('mp3')}>
                              mp3
                            </button>
                          </p>
                        </div>
                      </>
                    )}
                    {
                      downloadType && <BatchDownloadModal fileType={downloadType} setDownloadType={setDownloadType} assetType="favourites" />
                    }
                  </div>
                </div>
              )
            }
          </div>
          {results.length === 0 && resultsLoading ? (
            <div className="py-10 text-2xl font-bold text-center">Loading...</div>
          )
            : (
              <div>
                {(results.length > 0) ? (
                  <>
                    {results.map((asset) => <SearchResultItem key={asset.id} asset={asset} />)}
                    <ShowMoreResults />
                  </>
                )
                  : (
                    <div className="py-10 text-2xl font-bold text-center">No <span>favourites</span> found</div>
                  )
                }
              </div>
            )
          }
        </div>
        <MixerStickyBar />
        {
          mixerModeOn && <MixerOverlay />
        }
      </div>
    </>
  );
}

Favourites.propTypes = {
  history: PropTypes.shape({
    listen: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
  }),
};

export default withRouter(Favourites);
