import React from 'react';
import PropTypes from 'prop-types';

import BaseModal from '../../Components/BaseModal'
import GelIcon from '../../Components/GelIcon'
import checkIsIOS from '../../utilities/checkIsIOS'
import { THEME_COLOURS } from '../../constants'

const RecordingNotSupportedModal = ({ setShowRecordMixModal }) => {
  const { isIOS } = checkIsIOS();

  return (
    <BaseModal labelId="recordsupport_modal_title" descriptionId="recordsupport_desc">
      <div className="text-left">
        <div className="flex items-center justify-between px-6 py-8 sm:px-8">
          <div className="flex flex-row items-center space-x-3">
            <GelIcon name="music-track" fill={THEME_COLOURS.PRIMARY} className="w-5 h-5" />
            <span id="recordsupport_modal_title" className="text-2xl font-bold text-gray-900">Recording Not Supported</span>
          </div>
          <button type="button" className="p-2 rounded-md focus:outline-none focus:bg-gray-300 hover:bg-gray-300" onClick={() => setShowRecordMixModal(false)} aria-label="Close">
            <GelIcon name="close" fill={THEME_COLOURS.PRIMARY} />
          </button>
        </div>
        <div className="px-6 sm:px-8">
          {isIOS ? (
            <>
              <p id="recordsupport_desc">Unfortunately this feature is not currently available in this version of iOS.</p>
              <br/>
              <p>The mix recording function will work on most desktop browsers and Android.</p>
            </>
          ) : (
            <>
              <p id="recordsupport_desc">Unfortunately this feature is not currently available in this browser.</p>
              <br/>
              <p>You may wish to switch to a different browser in order to record your mix.</p>
            </>
          )}
          <br />
        </div>
        <div className="flex justify-center w-full mt-10 md:justify-end md:pb-8 md:px-6">
          <button
            className="w-full px-4 py-3 font-semibold text-white bg-teal-700 md:w-1/3 focus:outline-none hover:opacity-75 focus:opacity-75"
            onClick={() => setShowRecordMixModal(false)}
          >
            Okay, got it
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

RecordingNotSupportedModal.propTypes = {
  setShowRecordMixModal: PropTypes.func.isRequired,
};

export default RecordingNotSupportedModal;
