import React from 'react';
import PropTypes from 'prop-types';

import BaseModal from '../../Components/BaseModal'
import GelIcon from '../../Components/GelIcon'
import { THEME_COLOURS, LOCALSTORAGE_ACCEPT_FAVOURITES_LIMIT } from '../../constants'

const LimitInfoModal = ({ setShowLimitInfoModal }) => {
  const handleAcceptClick = () => {
    window.localStorage.setItem(LOCALSTORAGE_ACCEPT_FAVOURITES_LIMIT, true);
    setShowLimitInfoModal(false);
  }

  return (
    <BaseModal labelId="limit_modal_title" descriptionId="limit_modal_desc">
      <div className="text-left">
        <div className="flex items-center justify-between px-6 py-8 sm:px-8">
          <div className="flex flex-row items-center space-x-3">
            <GelIcon name="love-filled" fill={THEME_COLOURS.PRIMARY} className="w-5 h-5" />
            <span className="text-2xl font-bold text-gray-900" id="limit_modal_title">Your Favourites</span>
          </div>
          <button type="button" className="p-2 rounded-md focus:outline-none focus:bg-gray-300 hover:bg-gray-300" onClick={() => setShowLimitInfoModal(false)} aria-label="Close">
            <GelIcon name="close" fill={THEME_COLOURS.PRIMARY} />
          </button>
        </div>
        <div className="px-6 sm:px-8">
          <p id="limit_modal_desc"><strong>You can store up to 100 favourites in your browser.</strong></p>
          <br />
          <p>Your favourites will only be available in this browser, until you clear your cache.</p>
          <br />
          <p>
            You can choose to download your favourites using the ‘Download all favourites’
            button if you don’t want to lose what you have favourited.
          </p>
        </div>
        <div className="flex justify-center w-full mt-10 md:justify-end md:pb-8 md:px-6">
          <button
            className="w-full px-4 py-3 font-semibold text-white bg-teal-700 md:w-1/3 focus:outline-none hover:opacity-75 focus:opacity-75"
            onClick={handleAcceptClick}
          >
            Okay, got it
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

LimitInfoModal.propTypes = {
  setShowLimitInfoModal: PropTypes.func.isRequired,
};

export default LimitInfoModal;
